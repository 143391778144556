import { useCallback, useEffect, useState } from "react";
import BarCalendar from "../../../../Components/BarCalendar";
import axios from "axios";
import { Modal } from "antd";
import { Grid, Stack, Typography, Button } from "@mui/material";
import { Box } from "@mui/material";
import moment from "moment";
// You can add colors here!
const availableUserColors = {
  color1: {
    text: {
      color: "#4C6593",
    },
    start: {
      border: "2px solid #4C6593",
      borderBottom: "0px none",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
    middle: {
      border: "2px solid #4C6593",
      borderBottom: "0px none",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
    end: {
      border: "2px solid #4C6593",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
    single: {
      border: "2px solid #4C6593",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
  },
  color2: {
    text: {
      color: "#D66F0E",
    },
    start: {
      border: "2px solid #D66F0E",
      borderBottom: "0px none",
      opacity: 0.6,
      backgroundColor: "#ffab2b",
    },
    middle: {
      border: "2px solid #D66F0E",
      borderBottom: "0px none",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#ffab2b",
    },
    end: {
      border: "2px solid #D66F0E",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#ffab2b",
    },
    single: {
      border: "2px solid #D66F0E",
      opacity: 0.6,
      backgroundColor: "#ffab2b",
    },
  },
  color3: {
    text: {
      color: "#18BA43",
    },
    start: {
      border: "2px solid #18BA43",
      borderBottom: "0px none",
      opacity: 0.6,
      backgroundColor: "#6dd230",
    },
    middle: {
      border: "2px solid #18BA43",
      borderBottom: "0px none",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#6dd230",
    },
    end: {
      border: "2px solid #18BA43",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#6dd230",
    },
    single: {
      border: "2px solid #18BA43",
      opacity: 0.6,
      backgroundColor: "#6dd230",
    },
  },
  color4: {
    text: {
      color: "#EA2556",
    },
    start: {
      border: "2px solid #EA2556",
      borderBottom: "0px none",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
    middle: {
      border: "2px solid #EA2556",
      borderBottom: "0px none",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
    end: {
      border: "2px solid #EA2556",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
    single: {
      border: "2px solid #EA2556",
      opacity: 0.6,
      backgroundColor: "#4d7cfe",
    },
  },
  color5: {
    text: {
      color: "#1A7FCC",
    },
    start: {
      border: "2px solid #1A7FCC",
      borderBottom: "0px none",
      opacity: 0.6,
      backgroundColor: "#fe4d97",
    },
    middle: {
      border: "2px solid #1A7FCC",
      borderBottom: "0px none",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#fe4d97",
    },
    end: {
      border: "2px solid #1A7FCC",
      borderTop: "0px none",
      opacity: 0.6,
      backgroundColor: "#fe4d97",
    },
    single: {
      border: "2px solid #1A7FCC",
      opacity: 0.6,
      backgroundColor: "#fe4d97",
    },
  },
};

const ClientEmployeeAvailability = (props) => {
  const [eventTimes, setEventTimes] = useState([]);
  const [empAvail, setEmpAvail] = useState([]);
  const [clientAvail, setClientAvail] = useState([]);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [lockOn, setLockon] = useState(false);
  const [combAvail, setCombAvail] = useState([]);
  const [submitTimeClicked, setSubmitTimeClicked] = useState("");

  const [stMount, setStMount] = useState(false);
  const [series] = useState([
    {
      name: "Net Profit",
      data: [180, 90, 135, 114, 120, 145],
    },
    {
      name: "Revenue",
      data: [120, 45, 78, 150, 168, 99],
    },
  ]);
  // const getClientAvailability = useCallback(
  //   (clientUserId) => {
  //     axios
  //       .post("/schedule/get/clientAvailability", {
  //         ClientId: [clientUserId]
  //       })
  //       .then((response) => {
  //         let newAvailabilities = [];

  //         let fetchedAvailabilities = response.data.availabilities;

  //         let userAvailabilities = [];
  //         let combinedAvailabilities = {};
  //         let possibleAvailabilities = [];
  //         let newAvail;
  //         let eventIndex = 0;
  //         let possibleAvail;

  //         fetchedAvailabilities.forEach((availObj, userIndex) => {
  //           userAvailabilities = availObj.availability;
  //           let clientAvailabilities = userAvailabilities.filter(
  //             (jrClient) => jrClient.jobId === props.jr.job_id
  //           );
  //           clientAvailabilities.forEach((userAvail) => {
  //             newAvail = { ...userAvail };

  //             if (newAvail.startDate in combinedAvailabilities) {
  //               possibleAvailabilities = [
  //                 ...combinedAvailabilities[newAvail.startDate]
  //               ];

  //               for (
  //                 let availIndex = 0;
  //                 availIndex < possibleAvailabilities.length;
  //                 availIndex++
  //               ) {
  //                 possibleAvail = possibleAvailabilities[availIndex];
  //                 if (
  //                   (possibleAvail.startTime <= newAvail.startTime &&
  //                     possibleAvail.endTime >= newAvail.startTime) ||
  //                   (possibleAvail.startTime <= newAvail.endTime &&
  //                     possibleAvail.endTime >= newAvail.endTime) ||
  //                   (newAvail.startTime <= possibleAvail.startTime &&
  //                     newAvail.endTime >= possibleAvail.startTime) ||
  //                   (newAvail.startTime <= possibleAvail.endTime &&
  //                     newAvail.endTime >= possibleAvail.endTime) ||
  //                   possibleAvail.startTime - 1 == newAvail.endTime ||
  //                   possibleAvail.endTime == newAvail.startTime
  //                 ) {
  //                   newAvail.startTime = Math.min(
  //                     possibleAvail.startTime,
  //                     newAvail.startTime
  //                   );
  //                   newAvail.endTime = Math.max(
  //                     possibleAvail.endTime,
  //                     newAvail.endTime
  //                   );
  //                   possibleAvailabilities.splice(availIndex, 1);
  //                 }
  //               }
  //               combinedAvailabilities[newAvail.startDate] = [
  //                 ...possibleAvailabilities,
  //                 { ...newAvail }
  //               ];
  //             } else {
  //               combinedAvailabilities[userAvail.startDate] = [{ ...newAvail }];
  //             }
  //             newAvailabilities.push({
  //               ...userAvail,
  //               caption: props.jr.clientName,
  //               interactMode: "none",
  //               eventStyle:
  //                 userIndex + 1 > Object.keys(availableUserColors).length
  //                   ? "color2"
  //                   : "color1",
  //               event_id: eventIndex++
  //             });
  //           });
  //           setClientAvail(newAvailabilities);
  //           setCombAvail(combinedAvailabilities);
  //         });
  //       });
  //   },
  //   [props.jr.clientName, props.jr.job_id]
  // );

  // useEffect(() => {
  //   if (props.employee) {
  //     getEmployeeAvailability(props.employee.employee_user_id);
  //   }
  //   if (props.jr) {
  //     getClientAvailability(props.jr.client_id);
  //   }
  // }, [getClientAvailability, props.employee, props.jr]);

  const openScheduleModal = () => setScheduleModalOpen(!scheduleModalOpen);

  // const getEmployeeAvailability = (empUserId) => {
  //   setStMount(false);

  //   axios
  //     .post("/schedule/get/employeeAvailability", {
  //       EmpId: [empUserId]
  //     })
  //     .then((response) => {
  //       let newAvailabilities = [];
  //       let fetchedAvailabilities = response.data.availabilities;
  //       let userAvailabilities = [];

  //       let eventIndex = 0;

  //       fetchedAvailabilities.forEach((availObj, userIndex) => {
  //         userAvailabilities = availObj.availability;
  //         userAvailabilities.forEach((userAvail) => {
  //           newAvailabilities.push({
  //             ...userAvail,
  //             interactMode: "none",
  //             eventStyle:
  //               userIndex + 1 > Object.keys(availableUserColors).length
  //                 ? "color2"
  //                 : "color5",
  //             event_id: eventIndex++
  //           });
  //         });
  //       });

  //       setEmpAvail(newAvailabilities);
  //     });
  // };

  const createEvent = (selectedList) => {
    if (selectedList.length > 0) {
      let body = {};
      const firstItem = selectedList[0];
      let updatedItem;
      let startDate = new Date();

      if (firstItem.includes("Morning")) {
        updatedItem = firstItem.replace(/ Morning/, "");
      } else if (firstItem.includes("Afternoon")) {
        updatedItem = firstItem.replace(/ Afternoon/, "");
      } else if (firstItem.includes("Evening")) {
        updatedItem = firstItem.replace(/ Evening/, "");
      }

      // Get the current year, month, and day
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const currentDate = new Date().getDate();
      let datePart = new Date(updatedItem);
      datePart.setFullYear(currentYear);
      datePart.setMonth(currentMonth);

      // Create the startDate with the correct year, month, and day
      console.log(updatedItem);
      console.log(datePart);
      console.log("GHDGKJHDSGKJHSKJGHKGH");
      startDate = new Date(datePart);

      if (firstItem.includes("Morning")) {
        let startTime = new Date(datePart).setHours(8, 0, 0, 0); // 8am
        let endTime = new Date(datePart).setHours(12, 0, 0, 0); // 12pm
        body = {
          StartDate: new Date(startTime),
          StartTime: startTime,
          EndTime: endTime,
        };
        console.log(body, "MORNING");
      } else if (firstItem.includes("Afternoon")) {
        let startTime = new Date(datePart).setHours(12, 0, 0, 0); // 12pm
        let endTime = new Date(datePart).setHours(17, 0, 0, 0); // 5pm
        body = {
          StartDate: new Date(startTime),
          StartTime: startTime,
          EndTime: endTime,
        };
        console.log(body, "AFTERNOON");
      } else if (firstItem.includes("Evening")) {
        let startTime = new Date(datePart).setHours(17, 0, 0, 0); // 5pm
        let endTime = new Date(datePart).setHours(22, 0, 0, 0); // 10pm
        body = {
          StartDate: new Date(startTime),
          StartTime: startTime,
          EndTime: endTime,
        };
        console.log(body, "EVENING");
      }

      // Call props.onSubmit if needed
      props.onSubmit(body);
      openScheduleModal();
    }
  };

  return (
    <>
      <Button
        size="sm"
        type="button"
        variant="contained"
        color="primary"
        onClick={openScheduleModal}
        sx={{ ml: 2, float: "right" }}
      >
        Select Time
      </Button>

      <Modal
        title="Select Time for Event"
        open={scheduleModalOpen}
        onCancel={openScheduleModal}
        destroyOnClose={true}
        width={"800px"}
        footer={false}
      >
        <Grid>
          <Stack direction="row" spacing={1.5} sx={{ mb: 2 }}>
            <Box
              sx={{
                width: 15,
                height: 15,
                backgroundColor: "#61ddff",
              }}
            />{" "}
            <Typography variant="h6" color="secondary">
              Employee Availability
            </Typography>
            <Box
              sx={{
                width: 15,
                height: 15,
                backgroundColor: "#ffe561",
              }}
            />
            <Typography variant="h6" color="secondary">
              Client Availability
            </Typography>
            <Box
              sx={{
                width: 15,
                height: 15,
                backgroundColor: "#B0E1B0",
              }}
            />
            <Typography variant="h6" color="secondary">
              Selectable Availability
            </Typography>
            <Box
              sx={{
                width: 15,
                height: 15,
                backgroundColor: "#ffa500",
              }}
            />
            <Typography variant="h6" color="secondary">
              Selected Time
            </Typography>
          </Stack>
          <Typography variant="h6" color="secondary">
            You may only select one block for scheduling events.
          </Typography>
        </Grid>
        <BarCalendar
          availabilityArray={props.jr.client_availability}
          onSubmit={createEvent}
          jrData={props.jr}
          employeeAvailability={JSON.parse(props.employee.availability)}
          overlap={true}
        />
        {typeof eventTimes.startDate === "undefined" && submitTimeClicked ? (
          <Typography style={{ color: "red" }}>
            *Date and time are required to be selected.
          </Typography>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default ClientEmployeeAvailability;
