import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SaveCardForm from "./SaveCardForm";
import { useEffect, useState } from "react";
import axios from "axios";

const NewPaymentMethod = ({ clientSecret, onSuccess, successUrl }) => {
  const [options, setOptions] = useState(false);
  const [stripePromise, setStripePromise] = useState(false);

  useEffect(() => {
    axios.get("/api/config").then((res) => {
      const publishableKey = res.data.publishableKey;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    if (clientSecret) {
      setOptions({
        clientSecret: `${clientSecret}`,
        appearance: {
          // Appearance customization (if needed)
        },
      });
    } else {
      axios.get("/stripe/secret/setupIntent").then(({ data }) => {
        setOptions({
          clientSecret: data.clientSecret,
          appearance: {
            // Appearance customization (if needed)
          },
        });
      });
    }
  }, [clientSecret]);

  return (
    <>
      {options && stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <SaveCardForm onSuccess={onSuccess} successUrl={successUrl} />
        </Elements>
      )}
    </>
  );
};

export default NewPaymentMethod;
