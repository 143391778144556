import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  RadioGroup,
  Radio,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import {
  strengthColor,
  strengthIndicator,
} from "../../../Layout/utils/password-strength";

import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import axios from "axios";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { set } from "lodash";
import GetProvinceList from "./GetProvinceList";

const AuthRegister = () => {
  let navigate = useNavigate();
  const [level, setLevel] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [spinnerActive, setSpinnerActive] = useState(false);

  // feild states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [clientSecret, setClientSecret] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const sign = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const location = useLocation();
  const onSubmit = (event) => {
    event.preventDefault();
    const state = location.state;

    if (!firstName) {
      setErrorMessage("First Name is required");
      return;
    }
    if (!lastName) {
      setErrorMessage("Last Name is required");
      return;
    }
    if (!email) {
      setErrorMessage("Email is required");
      return;
    }
    if (!password) {
      setErrorMessage("Password is required");
      return;
    }
    if (!passwordConfirm) {
      setErrorMessage("Please confirm your password to continue");
      return;
    }
    if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match");
      return;
    }
    if (!streetAddress) {
      setErrorMessage("Street Address is required");
      return;
    }
    if (!city) {
      setErrorMessage("City is required");
      return;
    }
    if (!province) {
      setErrorMessage("Province is required");
      return;
    }
    if (!postalCode) {
      setErrorMessage("Postal Code is required");
      return;
    }

    setSubmitting(true);

    axios.post("auth/userExists", { email: email }).then(async (response) => {
      // setSubmitting(true);
      const userExists = response.data.exists;
      console.log({
        email: email,
        password: password,
        Terms: `${firstName}-${lastName}-${"testEsig"}${new Date().getFullYear()}T&C`,
        Policy: `${firstName}-${lastName}-${"testEsig"}${new Date().getFullYear()}PP`,
        host: window.location.host,
        Client: 1,
        Vendor: 0,
        FirstName: firstName,
        LastName: lastName,
        Street: streetAddress,
        City: city,
        Province: province,
        PostalCode: postalCode,
        Esign: "testEsig",
        vendorMode: false,
      });

      try {
        if (userExists) {
          setSubmitting(false);
          setErrorMessage("User with this email already exists");
          return;
        } else {
          let Esignature = sign(15);
          console.log(state);
          axios
            .post("/auth/register", {
              email: email,
              password: password,
              Terms: `${firstName}-${lastName}-${Esignature}${new Date().getFullYear()}T&C`,
              Policy: `${firstName}-${lastName}-${Esignature}${new Date().getFullYear()}PP`,
              host: window.location.host,
              Client: 1,
              Vendor: 0,
              FirstName: firstName,
              LastName: lastName,
              Street: streetAddress,
              City: city,
              Province: province,
              PostalCode: postalCode,
              Esign: Esignature,
              vendorMode: false,
            })
            .then((res) => {
              console.log(state, "fdsgsdgsdfwvcwe");
              console.log(res.data);
              if (state) {
                const jobData = {
                  userID: res.data.clientID,
                  propertyId: res.data.clientPropertyID,
                  jobTitle: state.jobData.jobTitle,
                  category: state.jobData.category,
                  jobDescription: state.jobData.jobDescription,
                  directAssigned: 0,
                  sendMarket: state.jobData.sendMarket,
                  activityDesc: state.jobData.activityDesc,
                  userName: `${firstName} ${lastName}`,
                  taskArray: state.jobData.taskArray,
                  lat: res.data.lat,
                  lon: res.data.lon,
                };
                console.log(jobData, "JOBDATAkjhjsigfiys");
                axios
                  .post("/jobRequest/newJobRequestSignup", jobData)
                  .then((response) => {
                    navigate("/accountcreated");
                    setSubmitting(false);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPassword("");
                    setPasswordConfirm("");
                    setStreetAddress("");
                    setCity("");
                    setProvince("");
                    setPostalCode("");
                    setErrorMessage("");
                    setClientSecret(res.data.clientSecret);
                    // setCreditCardModal(true);
                    // onFinish();
                  });
              } else {
                navigate("/accountcreated");
                setSubmitting(false);
                setFirstName("");
                setLastName("");
                setEmail("");
                setPassword("");
                setPasswordConfirm("");
                setStreetAddress("");
                setCity("");
                setProvince("");
                setPostalCode("");
                setErrorMessage("");
                setClientSecret(res.data.clientSecret);
                // onFinish();
              }
            });
          // navigate(
          //   "/signup/vendorDecision",
          //   state !== null
          //     ? {
          //         state: {
          //           userData: {
          //             email: email,
          //             password: password,
          //             Terms: `${firstName}-${lastName}-${Esignature}${new Date().getFullYear()}T&C`,
          //             Policy: `${firstName}-${lastName}-${Esignature}${new Date().getFullYear()}PP`,
          //             host: window.location.host,
          //             Client: 1,
          //             Vendor: 0,
          //             FirstName: firstName,
          //             LastName: lastName,
          //             Street: streetAddress,
          //             City: city,
          //             Province: province,
          //             PostalCode: postalCode,
          //             Esign: Esignature,
          //             vendorMode: false,
          //           },
          //           jobData: {
          //             city: city,
          //             category: state.jobData.category,
          //             jobTitle: state.jobData.jobTitle,
          //             jobDescription: state.jobData.jobDescription,
          //             sendMarket: state.jobData.sendMarket,
          //             taskArray: state.jobData.taskArray,
          //             activityDesc: state.jobData.activityDesc,
          //           },
          //         },
          //       }
          //     : {
          //         state: {
          //           userData: {
          //             email: email,
          //             password: password,
          //             Terms: `${firstName}-${lastName}-${"Esignature"}${new Date().getFullYear()}T&C`,
          //             Policy: `${firstName}-${lastName}-${"Esignature"}${new Date().getFullYear()}PP`,
          //             host: window.location.host,
          //             Client: 1,
          //             Vendor: 0,
          //             FirstName: firstName,
          //             LastName: lastName,
          //             Street: streetAddress,
          //             City: city,
          //             Province: province,
          //             PostalCode: postalCode,
          //             Esign: "testEsig",
          //             vendorMode: false,
          //           },
          //         },
          //       }
          // )
          // .then(async (result) => {
          //   setFirstName("");
          //   setLastName("");
          //   setEmail("");
          //   setPassword("");
          //   setPasswordConfirm("");
          //   setStreetAddress("");
          //   setCity("");
          //   setProvince("");
          //   setPostalCode("");
          //   setErrorMessage("");
          //   // setClientSecret(res.data.clientSecret);
          //   // setSubmitting(false);
          // });
        }
      } catch (err) {
        setSubmitting(false);
        console.error(err);
      }
    });

    // navigate("/signup/clientDetails", {
    //   state: {
    //     email: email,
    //     password: password,
    //     isClient: 1,
    //   },
    // });
    // setSpinnerActive(true);
    // axios.post("/auth/userExists", { email: email }).then((response) => {
    //   console.log(response);
    //   setSpinnerActive(false);
    //   const userExists = response.data.exists;
    //   try {
    //     if (userExists) {
    //       setErrorMessage("User with this email already exists");
    //       return navigate("/signup");
    //     } else {
    //       return navigate("/signup/clientDetails", {
    //         state: {
    //           email: email,
    //           password: password,
    //           isClient: 1,
    //         },
    //       });
    //     }
    //   } catch (err) {
    //     console.error(err);
    //   }
    // });
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {/* First Name Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="first-name-signup">First Name*</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="first-name-signup"
                  type="text"
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="John"
                  inputProps={{}}
                />
              </Stack>
            </Grid>

            {/* Last Name Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="last-name-signup">Last Name*</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="last-name-signup"
                  type="text"
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Doe"
                  inputProps={{}}
                />
              </Stack>
            </Grid>

            {/* Email Address Input */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="email-login"
                  type="Email"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="email"
                  inputProps={{}}
                />
              </Stack>
            </Grid>

            {/* Password Input */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password-signup">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="password-signup"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    changePassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="******"
                  inputProps={{}}
                />
              </Stack>
            </Grid>

            {/* Confirm Password Input */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="passwordConfirm-signup">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="passwordConfirm-signup"
                  type={showPassword ? "text" : "password"}
                  value={passwordConfirm}
                  name="password"
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="******"
                  inputProps={{}}
                />
              </Stack>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Box
                      sx={{
                        bgcolor: level?.color,
                        width: 85,
                        height: 8,
                        borderRadius: "7px",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" fontSize="0.75rem">
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            {/* Address Search Input */}
            <Grid item xs={12} md={12}>
              <GooglePlacesAutocomplete
                className={"css-1hb7zxy-IndicatorsContainer"}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEYS}
                autocompletionRequest={{
                  componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                  types: ["address"],
                }}
                selectProps={{
                  name: "streetAddress",
                  value: streetAddress,
                  onChange: async (selectedOption) => {
                    console.log(selectedOption);

                    const placeId = selectedOption.value.place_id;
                    const key = process.env.REACT_APP_GOOGLE_API_KEYS;

                    const response = await axios.get(
                      `/jobRequest/place-details`,
                      {
                        params: { placeId, key },
                      }
                    );
                    console.log(response.data.result);

                    const addressComponents =
                      response.data.result.address_components;
                    const streetNumber =
                      addressComponents.find((component) =>
                        component.types.includes("street_number")
                      )?.long_name || "";
                    const route =
                      addressComponents.find((component) =>
                        component.types.includes("route")
                      )?.long_name || "";
                    const city =
                      addressComponents.find((component) =>
                        component.types.includes("locality")
                      )?.long_name || "";
                    const province =
                      addressComponents.find((component) =>
                        component.types.includes("administrative_area_level_1")
                      )?.short_name || "";

                    // const addressComponents = selectedOption.label.split(", ");
                    // console.log(addressComponents);
                    // const street = addressComponents[0];
                    // const city = addressComponents[1];
                    // const province = addressComponents[2];
                    const postalCode =
                      response.data.result.address_components.find(
                        (component) => component.types.includes("postal_code")
                      )?.long_name || "";

                    // Combine street number and route for the full street address
                    const street = `${streetNumber} ${route}`.trim();

                    // Update city and province fields in Formik values
                    setStreetAddress(street);
                    setCity(city);
                    setProvince(province);
                    setPostalCode(postalCode);
                  },
                  placeholder: "Find address here",
                }}
              />
              <style>
                {`
          .css-1hb7zxy-IndicatorsContainer {
            display: none; // Hide dropdown icon
          }
        `}
              </style>
            </Grid>

            {/* Street Address Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="street-address-signup">
                  Street Address
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="street-address-signup"
                  type="text"
                  value={streetAddress}
                  name="streetAddress"
                  onChange={(e) => setStreetAddress(e.target.value)}
                  placeholder="123 Main St"
                  inputProps={{}}
                />
              </Stack>
            </Grid>

            {/* City Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="city-signup">City</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="city-signup"
                  type="text"
                  value={city}
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Toronto"
                  inputProps={{}}
                />
              </Stack>
            </Grid>

            {/* Province Input */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Province</InputLabel>
                <Select
                  id="province-signup"
                  value={province}
                  name="province"
                  defaultValue={"Select Province"}
                  onChange={(e) => setProvince(e.target.value)}
                >
                  {GetProvinceList().map((provinces) => (
                    <MenuItem
                      value={provinces.province_shortform}
                      key={provinces.province_shortform}
                    >
                      {provinces.province_name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>

            {/* Postal Code Input */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="postal-code-signup">
                  Postal Code
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="postal-code-signup"
                  type="text"
                  value={postalCode}
                  name="postalCode"
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder="A1A 1A1"
                  inputProps={{}}
                />
              </Stack>
            </Grid>

            {/* Error Message */}
            {errorMessage !== "" && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="error">
                  {errorMessage}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#01552f" }}
                >
                  Sign Up
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
      </>
    </LoadingSpinner>
  );
};

export default AuthRegister;
