import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "antd";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import NewPaymentMethod from "../../NewPaymentMethod";
import AuthWrapper from "../auth-pages/AuthWrapper";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";

const {
  FormControl,
  RadioGroup,
  Button,
  FormControlLabel,
  Radio,
  Grid,
  Typography,
} = require("@mui/material");
const { Formik } = require("formik");
const { useState } = require("react");

const VendorDecision = () => {
  const navigate = useNavigate();
  let { state } = useLocation();
  console.log(state);
  const [isVendor, setIsVendor] = useState();
  const [clientSecret, setClientSecret] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [showNextPage, setShowNextPage] = useState(false);

  const onFinish = () => {
    navigate("/accountcreated");
  };

  const onSubmit = () => {
    setSubmitting(true);
    console.log(isVendor);
    if (isVendor === "Yes") {
      navigate("/signup/companyDetails", {
        state: {
          email: state.email,
          password: state.password,
          Terms: state.Terms,
          Policy: state.Policy,
          host: state.host,
          Client: state.Client,
          FirstName: state.FirstName,
          LastName: state.LastName,
          PhoneNumber: state.PhoneNumber,
          DOB: state.DOB,
          // Gender: state.Gender,
          Street: state.Street,
          City: state.City,
          Province: state.Province,
          PostalCode: state.PostalCode,
          Esign: state.Esign,
        },
      });
    } else {
      console.log(state);
      try {
        axios
          .post("/auth/register", {
            email: state.userData.email,
            password: state.userData.password,
            Terms: state.userData.Terms,
            Policy: state.userData.Policy,
            host: state.userData.host,
            Client: state.userData.Client,
            Vendor: 0,
            FirstName: state.userData.FirstName,
            LastName: state.userData.LastName,
            PhoneNumber: state.userData.PhoneNumber,
            // DOB: state.DOB,
            // Gender: state.Gender,
            Street: state.userData.Street,
            City: state.userData.City,
            Province: state.userData.Province,
            PostalCode: state.userData.PostalCode,
            Esign: state.userData.Esign,
            vendorMode: false,
          })
          .then((res) => {
            console.log(state, "fdsgsdgsdfwvcwe");
            console.log(res.data);
            if (state.jobData) {
              const jobData = {
                userID: res.data.clientID,
                propertyId: res.data.clientPropertyID,
                jobTitle: state.jobData.jobTitle,
                category: state.jobData.category,
                jobDescription: state.jobData.jobDescription,
                directAssigned: 0,
                sendMarket: state.jobData.sendMarket,
                activityDesc: state.jobData.activityDesc,
                userName: `${state.userData.FirstName} ${state.userData.LastName}`,
                taskArray: state.jobData.taskArray,
                lat: res.data.lat,
                lon: res.data.lon,
              };
              console.log(jobData, "JOBDATAkjhjsigfiys");
              axios
                .post("/jobRequest/newJobRequestSignup", jobData)
                .then((response) => {
                  setSubmitting(false);
                  setClientSecret(res.data.clientSecret);
                  // setCreditCardModal(true);
                  onFinish();
                });
            } else {
              setSubmitting(false);
              setClientSecret(res.data.clientSecret);
              onFinish();
            }
          });
      } catch (err) {
        console.error(err.message);
        setSubmitting(false);
      }
    }
  };

  return (
    <LoadingSpinner isActive={submitting}>
      <AuthWrapper>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} justifyContent={"center"}>
                <Grid item xs={12}>
                  <Typography variant="h3">Providing Services</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="p">
                    Do you wish to provide services to clients?
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
                    <RadioGroup
                      defaultValue=""
                      name="customized-radios"
                      row={true}
                      value={isVendor}
                      onChange={(e) => {
                        setIsVendor(e.target.value);
                        setShowNextPage(true);
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        disabled={isVendor === "Yes"}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        disabled={isVendor === "No"}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={!showNextPage}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      // style={{ backgroundColor: "#01552f" }}
                    >
                      Continue
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        {/* <Modal
          title="Add Payment Details"
          open={creditCardModal}
          onCancel={onFinish}
          footer={false}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(90vh - 200px)" }}
        >
          <NewPaymentMethod
            onSuccess={onFinish}
            clientSecret={clientSecret}
            successUrl={window.location.origin + "/accountcreated"}
          />
        </Modal> */}
      </AuthWrapper>
    </LoadingSpinner>
  );
};
export default VendorDecision;
