import { useEffect, useState } from "react";
import filledStar from "../../../../images/filledStar.png";
import halfFilledStar from "../../../../images/halfFilledStar.png";
import unfilledStar from "../../../../images/unfilledStar.png";

const StarRating = ({ rating, size }) => {
  const [filledStars, setFilledStars] = useState(Math.floor(rating));
  const [halfStar, setHalfStar] = useState(false);

  useEffect(() => {
    setFilledStars(Math.floor(rating));
    setHalfStar(
      rating - Math.floor(rating) >= 0.3 && rating - Math.floor(rating) < 0.7
    );
  }, [rating]);

  const starArray = Array.from(Array(5).keys());

  return (
    <div className="star-rating">
      {starArray.map((index) => (
        <span
          style={{ fontSize: size ? size : "2rem" }}
          key={index}
          className={`star ${index < filledStars ? "filled" : ""} ${
            halfStar && index === filledStars ? "half-filled" : ""
          }`}
        >
          {index < filledStars ? (
            <img
              style={{ width: "2rem" }}
              id="icon"
              src={filledStar}
              alt="icon"
            />
          ) : halfStar && index === filledStars ? (
            <img
              style={{ width: "2rem" }}
              id="icon"
              src={halfFilledStar}
              alt="icon"
            />
          ) : (
            <img
              style={{ width: "2rem" }}
              id="icon"
              src={unfilledStar}
              alt="icon"
            />
          )}
        </span>
      ))}
    </div>
  );
};

export default StarRating;
