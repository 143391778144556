import React, { useState } from "react";
import { Modal } from "antd";
import BarCalendar from "../../../../Components/BarCalendar";
import axios from "axios";
import { Button } from "@mui/material";
const source = axios.CancelToken.source();

const SetClientAvailability = (props) => {
  const onSubmit = async (availabilityList) => {
    props.setAvailabilityArray(availabilityList);
    props.onClose();
  };
  return (
    <Modal
      title="Pick a time for the vendor to complete the work"
      open={true}
      onCancel={props.onClose}
      destroyOnClose={true}
      width={"800px"}
      footer={false}
    >
      <BarCalendar
        availabilityArray={props.availabilityArray}
        onSubmit={onSubmit}
        jrData={props.jrData}
        editable={true}
      />
    </Modal>
  );
};

export default SetClientAvailability;
