import React, { useEffect, useState, useRef } from "react";
import { Stack, Typography } from "@mui/material";

const ChatContainer = ({
  messages,
  sending,
  user,
  stopTyping,
  setStopTyping,
  setMessages,
  setStopAccepted,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [messagesWithoutLast, setMessagesWithoutLast] = useState([...messages]);
  const newMessageRef = useRef("");

  const stopTypingEffect = () => {
    if (stopTyping) {
      // Clear incomplete message
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage.user === "Fixxie") {
        console.log(messages, "MESSAGES");
        const incompleteMessage = newMessageRef.current;

        // Replace last message with the incomplete message
        // const updatedMessages = [...messages];
        messages.pop(); // Remove last message
        console.log(messages, "MESSAGES");
        // updatedMessages.push({
        //   user: "Fixxie",
        //   message: incompleteMessage,
        //   attachments: lastMessage.attachments || [],
        // });

        setMessages([
          ...messages,
          {
            user: "Fixxie",
            message: incompleteMessage,
            attachments: lastMessage.attachments || [],
          },
        ]);
        console.log(messages, "MESSAGES");
        setIsTyping(false);
        setStopAccepted(false);
        newMessageRef.current = ""; // Reset typing ref
        setNewMessage(""); // Reset visible message
        setStopTyping(false); // Reset stopTyping flag in parent
      }
    }
  };

  useEffect(() => {
    console.log("HELLO");
    stopTypingEffect();
  }, [stopTyping]);

  const messageContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to bottom whenever messages update
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [newMessage, messages]);

  // To track when new Fixxie message appears
  const [isTyping, setIsTyping] = useState(false);

  // To keep track of the index of the last Fixxie message in the messages array
  const lastFixxieMessageIndexRef = useRef(null);

  const hasMounted = useRef(false);

  // When a new message is added, trigger typing effect
  useEffect(() => {
    if (!hasMounted.current && messages.length > 1) {
      hasMounted.current = true; // Mark as mounted after the first render
      return; // Skip the effect on initial render
    }

    // Check if the last message is from Fixxie
    const lastMessage = messages[messages.length - 1];

    if (lastMessage && lastMessage.user === "Fixxie") {
      setMessagesWithoutLast(messages.slice(0, -1)); // Remove the last message from display

      // If it's a new message (not a repeat), trigger the typing animation
      if (lastFixxieMessageIndexRef.current !== messages.length - 1) {
        lastFixxieMessageIndexRef.current = messages.length - 1; // Update ref to the new last message index
        setIsTyping(true); // Start typing animation
        let newMessageText = lastMessage.message;
        newMessageText = newMessageText
          .replace(/<b>/g, "")
          .replace(/<\/b>/g, "")
          .replace(/<br \/>/g, "")
          .replace(/<a\b[^>]*>/g, "")
          .replace(/<\/a>/g, ""); // Remove <b> and </b> tags
        newMessageRef.current = ""; // Reset the ref to empty before typing

        let index = 0;
        const typingInterval = setInterval(() => {
          // Update the ref with the current letter
          newMessageRef.current += newMessageText[index];

          // Now, set the state to trigger re-render with the updated message
          setNewMessage(newMessageRef.current);

          // Move to the next character
          index += 1;

          // If we've typed all characters, stop the typing effect
          if (index === newMessageText.length) {
            clearInterval(typingInterval);
            setIsTyping(false); // Stop typing animation when done
            setStopAccepted(false);
            setNewMessage(""); // Clear newMessage to reset after typing
          }
        }, 15); // Typing speed

        return () => clearInterval(typingInterval); // Cleanup interval when the component is unmounted
      }
    }
  }, [messages]); // Dependency array ensures this effect runs when messages change

  //   console.log(messages, "ORIGINAL");
  //   console.log(messagesWithoutLast, "WITHOUTLAST");
  return (
    <Stack
      id="messageContainer"
      style={{
        // borderTop: "1px solid black",
        // borderBottom: "1px solid black",
        width: "30rem",
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
        overflowY: "auto",
        padding: "0.5rem",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
      }}
      ref={messageContainerRef}
    >
      {!isTyping &&
        messages.length > 0 &&
        messages.map((message, index) => (
          <Stack
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems:
                message.user === user.first_name ? "flex-end" : "flex-start",
              margin: "0.5rem 0",
            }}
          >
            {/* {console.log(message)} */}
            <Stack
              style={{
                maxWidth: "70%",
                padding: "0.75rem",
                borderRadius: "15px",
                backgroundColor:
                  message.user === user.first_name ? "#017a46" : "#c9c9c9",
                color: message.user === user.first_name ? "white" : "black",
                boxShadow: `
                  0px 4px 6px rgba(0, 0, 0, 0.1),
                  inset 0px 2px 4px rgba(0, 0, 0, 0.2)
                `,
                cursor: "text",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  color:
                    message.user === user.first_name ? "#FFFFFF" : "#000000",
                }}
              >
                {message.user}
              </Typography>
              <Stack
                variant="body1"
                // style={{ wordWrap: "break-word" }}
                style={{ wordWrap: "break-word", fontSize: "14px" }}
                dangerouslySetInnerHTML={{
                  __html: message.message,
                }}
              ></Stack>
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.15rem",
                  // overflowX: "scroll",
                  // overflowY: "hidden",
                }}
              >
                {message.attachments &&
                  message.attachments.length > 0 &&
                  message.attachments.map((image, index) => (
                    // console.log(image, "IMAGEEEE")
                    <div
                      key={index}
                      // className="attachment"
                      style={{
                        height: "2.5em",
                        width: "fit-content",
                        marginRight: "5px",
                      }}
                    >
                      <img
                        style={{ height: "100%" }}
                        key={index}
                        src={image}
                        alt=""
                      />
                    </div>
                  ))}
              </Stack>
            </Stack>
          </Stack>
        ))}
      {sending && (
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "0.5rem 0",
          }}
        >
          <Stack
            style={{
              width: "25%",
              height: "4.5rem",
              padding: "0.75rem",
              borderRadius: "15px",
              backgroundColor: "#c9c9c9",
              color: "black",
              boxShadow: `
                0px 4px 6px rgba(0, 0, 0, 0.1),
                inset 0px 2px 4px rgba(0, 0, 0, 0.2)
              `,
              cursor: "text",
            }}
          >
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Fixxie
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                display: "inline-flex",
                gap: "5px",
                fontSize: "12px",
                fontFamily: "sans-serif",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  animation: "grow-shrink 1.5s infinite ease-in-out",
                  animationDelay: "0s",
                }}
              >
                .
              </span>
              <span
                style={{
                  animation: "grow-shrink 1.5s infinite ease-in-out",
                  animationDelay: "0.5s",
                }}
              >
                .
              </span>
              <span
                style={{
                  animation: "grow-shrink 1.5s infinite ease-in-out",
                  animationDelay: "1s",
                }}
              >
                .
              </span>
            </Typography>
          </Stack>
        </Stack>
      )}
      {isTyping &&
        messagesWithoutLast.length > 0 &&
        messagesWithoutLast.map((message, index) => (
          <Stack
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems:
                message.user === user.first_name ? "flex-end" : "flex-start",
              margin: "0.5rem 0",
            }}
          >
            <Stack
              style={{
                maxWidth: "70%",
                padding: "0.75rem",
                borderRadius: "15px",
                backgroundColor:
                  message.user === user.first_name ? "#017a46" : "#c9c9c9",
                color: message.user === user.first_name ? "white" : "black",
                boxShadow: `
              0px 4px 6px rgba(0, 0, 0, 0.1),
              inset 0px 2px 4px rgba(0, 0, 0, 0.2)
            `,
                cursor: "text",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  color:
                    message.user === user.first_name ? "#FFFFFF" : "#000000",
                }}
              >
                {message.user}
              </Typography>
              <Stack
                variant="body1"
                // style={{ wordWrap: "break-word" }}
                style={{ wordWrap: "break-word", fontSize: "14px" }}
                dangerouslySetInnerHTML={{
                  __html: message.message, // Replace newlines with <br />
                }}
              ></Stack>
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.15rem",
                  // overflowX: "scroll",
                  // overflowY: "hidden",
                }}
              >
                {message.attachments &&
                  message.attachments.length > 0 &&
                  message.attachments.map((image, index) => (
                    <div
                      key={index}
                      // className="attachment"
                      style={{
                        height: "2.5em",
                        width: "fit-content",
                        marginRight: "5px",
                      }}
                    >
                      <img
                        style={{ height: "100%" }}
                        key={index}
                        src={image}
                        alt=""
                      />
                    </div>
                  ))}
              </Stack>
            </Stack>
          </Stack>
        ))}

      {/* Show the typing animation if isTyping is true */}
      {isTyping && (
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems:
              messages[messages.length - 1]?.user === user.first_name
                ? "flex-end"
                : "flex-start",
            margin: "0.5rem 0",
          }}
        >
          <Stack
            style={{
              maxWidth: "70%",
              padding: "0.75rem",
              borderRadius: "15px",
              backgroundColor: "#c9c9c9",
              color: "black",
              boxShadow: `
            0px 4px 6px rgba(0, 0, 0, 0.1),
            inset 0px 2px 4px rgba(0, 0, 0, 0.2)
          `,
              cursor: "text",
            }}
          >
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Fixxie
            </Typography>
            <Stack
              // variant="body1"
              style={{ wordWrap: "break-word", fontSize: "14px" }}
            >
              {newMessage}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ChatContainer;
