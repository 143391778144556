import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import AuthWrapper from "../auth-pages/AuthWrapper";
import * as Yup from "yup";
import GetProvinceList from "./GetProvinceList";
import PhoneInput from "../../../Components/FormComponents/PhoneInput";
import DatePicker from "../../../Components/FormComponents/DatePicker";
import { useState } from "react";

const AdminIntakeDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [addressError, setAddressError] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // const [position, setPosition] = useState({ lat: null, lon: null });

  const onSubmit = (values) => {
    setSubmitting(true);
    const {
      firstName,
      lastName,
      dateOfBirth,
      phone,
      streetAddress,
      city,
      province,
      postalCode,
      bio,
      services,
      position,
    } = values;

    if (position.lat === null || position.lon === null) {
      setAddressError(true);
    } else if (services === "withCompany") {
      navigate("/signup/companyDetails", {
        state: {
          email: location.state.email,
          password: location.state.password,
          isVendor: location.state.isVendor,
          FirstName: firstName,
          LastName: lastName,
          DOB: dateOfBirth,
          PhoneNumber: phone,
          StreetAddress: streetAddress,
          City: city,
          Province: province,
          PostalCode: postalCode,
          Biography: bio,
          position,
        },
      });
    } else if (services === "withoutCompany") {
      navigate("/signup/vendorWithoutCompany", {
        state: {
          email: location.state.email,
          password: location.state.password,
          isSoloVendor: location.state.isVendor,
          FirstName: firstName,
          LastName: lastName,
          DOB: dateOfBirth,
          PhoneNumber: phone,
          StreetAddress: streetAddress,
          City: city,
          Province: province,
          PostalCode: postalCode,
          Biography: bio,
          position,
        },
      });
    }
    setSubmitting(false);
  };

  return (
    <AuthWrapper>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          dateOfBirth: "1970-01-01",
          phone: "",
          streetAddress: "",
          city: "",
          province: "",
          postalCode: "",
          bio: "",
          services: "",
          position: { lat: null, lon: null },
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .max(255, "Too Long")
            .required("First name is required"),
          lastName: Yup.string().max(255).required("Last name is required"),
          dateOfBirth: Yup.string().max(255),
          phone: Yup.string()
            .min(15, "phoneNumber is required")
            .max(255)
            .required("phoneNumber is required"),
          streetAddress: Yup.string()
            .max(255)
            .required("Street address is required"),
          city: Yup.string().max(255).required("City is required"),
          province: Yup.string().max(255).required("Province is required"),
          postalCode: Yup.string().max(255).required("Postal code is required"),
          bio: Yup.string().max(255).required("Bio is required"),
          services: Yup.string()
            .matches(/(withoutCompany)|(withCompany)/)
            .required("Services type is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          handleChange,
          touched,
          values,
          validateForm,
        }) => (
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Divider
                    sx={{
                      color: "#46505A",
                      fontSize: 34,
                      fontWeight: "medium",
                    }}
                  >
                    User Details
                  </Divider>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>First Name</InputLabel>
                  <OutlinedInput
                    type="firstName"
                    value={values.firstName}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  {touched.firstName && errors.firstName && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.firstName}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Last Name</InputLabel>
                  <OutlinedInput
                    type="lastName"
                    value={values.lastName}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  {touched.lastName && errors.lastName && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.lastName}
                    </Typography>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Date of Birth</InputLabel>
                  <DatePicker
                    required
                    type="date"
                    name="dateOfBirth"
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.dateOfBirth && errors.dateOfBirth && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.dateOfBirth}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Phone</InputLabel>
                  <PhoneInput
                    name="phone"
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phone && errors.phone && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.phone}
                    </Typography>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Street Address</InputLabel>
                  <OutlinedInput
                    id="streetAddress-signup"
                    type="streetAddress"
                    value={values.streetAddress}
                    name="streetAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Street Address"
                  />
                  {touched.streetAddress && errors.streetAddress && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.streetAddress}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>City</InputLabel>
                  <OutlinedInput
                    id="city-signup"
                    type="city"
                    value={values.city}
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="city"
                  />
                  {touched.city && errors.city && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.city}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Province</InputLabel>
                  <Select
                    id="province-signup"
                    value={values.province}
                    name="province"
                    defaultValue={"Select Province"}
                    onChange={handleChange}
                  >
                    {GetProvinceList().map((provinces) => (
                      <MenuItem
                        value={provinces.province_shortform}
                        key={provinces.province_shortform}
                      >
                        {provinces.province_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.province && errors.province && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.province}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Postal Code</InputLabel>
                  <OutlinedInput
                    id="postalCode-signup"
                    type="postalCode"
                    value={values.postalCode}
                    name="postalCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Postal Code"
                  />
                  {touched.postalCode && errors.postalCode && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.postalCode}
                    </Typography>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={12}>
                {addressError && (
                  <Typography noWrap variant="h8" color="#f00">
                    *Can't find location please check spelling
                  </Typography>
                )}
                <Stack spacing={1}>
                  <InputLabel>Tell us about you</InputLabel>
                  <TextField
                    id="bio-signup"
                    type="bio"
                    value={values.bio}
                    name="bio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="About you..."
                    inputProps={{
                      maxLength: 500,
                      width: "100%",
                    }}
                    multiline
                  />
                  {touched.bio && errors.bio && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.bio}
                    </Typography>
                  )}
                </Stack>
              </Grid>

              <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
                <RadioGroup
                  value={values.services}
                  name="services"
                  row={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="withoutCompany"
                    control={<Radio />}
                    label="Without company?"
                  />
                  <FormControlLabel
                    value="withCompany"
                    control={<Radio />}
                    label="With Company?"
                  />
                </RadioGroup>
                {touched.services && errors.services && (
                  <Typography noWrap variant="h8" color="#ff0000">
                    *{errors.services}
                  </Typography>
                )}
              </FormControl>

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disabled={btnDisabled}
                    fullWidth
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setBtnDisabled(true);
                      axios
                        .post("/auth/checkLocation", {
                          Street: values.streetAddress,
                          City: values.city,
                          Province: values.province,
                        })
                        .then((response) => {
                          if (!response.data.found) {
                            setAddressError(true);
                            validateForm();
                          } else {
                            // setPosition({
                            //   lat: response.data.lat,
                            //   lon: response.data.lon,
                            // });
                            values.position = {
                              lat: response.data.lat,
                              lon: response.data.lon,
                            };
                            setAddressError(false);
                            handleSubmit(e);
                          }
                          setBtnDisabled(false);
                        });
                    }}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Continue
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </AuthWrapper>
  );
};

export default AdminIntakeDetails;
