import React, { useState, useEffect } from "react";
import { useUserState } from "../../../../Context/UserContext";
import axios from "axios";
import "./request.css";

import GetServices from "../../API/GetServices";
import {
  Button,
  Grid,
  Select,
  InputLabel,
  OutlinedInput,
  Stack,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  useMediaQuery,
} from "@mui/material";

import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import { Modal } from "antd";

import SetClientAvailability from "../Availability/SetClientAvailability";
import { useNavigate } from "react-router";

function JobRequest(props) {
  const { user } = useUserState();
  const [marketStatus, setMarketStatus] = useState(1);
  const [assignStatus, setAssignStatus] = useState(0);
  const [category, setCategory] = useState(
    typeof props.jrData !== "undefined"
      ? props.jrData.job_category
      : "Access & Security"
  );
  const [shortDesc, setShortDesc] = useState(
    typeof props.jrData !== "undefined" ? props.jrData.job_title : ""
  );

  const [longDesc, setLongDesc] = useState(
    typeof props.jrData !== "undefined" ? props.jrData.job_description : ""
  );
  const [previousVendor, setPreviousVendor] = useState("");
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [property, setProperty] = useState(0);
  const [status, setStatus] = useState(0);
  const [taskArray, setTaskArray] = useState(false);
  const [availabilityArray, setAvailabilityArray] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);

  const navigate = useNavigate();

  const radioHandler = (statusNum) => {
    setStatus(statusNum);
  };
  console.log(property);
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    axios.get("/property/getPropertyDetails").then((res) => {
      console.log(res.data.property);
      setProperties(res.data.property);
      res.data.property.forEach((prop, index) => {
        if (user.street === prop.street && user.city === prop.city) {
          setProperty(index);
        }
      });
    });
  }, []);

  const radioHandlerMarket = () => {
    setMarketStatus(1);
    setAssignStatus(0);
  };

  const radioHandlerAssign = () => {
    setAssignStatus(1);
    setMarketStatus(0);
  };

  const checkSubmit = () => {
    console.log("CHECKSUBMITENTERED");
    if (!props.editForm) {
      if (
        property === "selectProperty" ||
        !shortDesc ||
        !longDesc ||
        marketStatus + assignStatus === 0 ||
        !availabilityArray
      ) {
        setFormError(true);
        return;
      }
      setSubmitting(true);
      onSubmit();
    } else {
      setSubmitting(true);
      onUpdate();
    }
  };

  // const deleteUploadedImage = (imageId) => {
  //   const updatedImages = props.jrImages.filter(
  //     (image) => image.jobrequest_images_id !== imageId
  //   );

  //   props.setJrImages(updatedImages);
  // };

  const onSubmit = async () => {
    const finishSubmit = () => {
      props.displaySubmitAlert();
      props.onClose();
    };

    const prompt = {
      prompt: longDesc,
    };

    const categoryResponse = await axios.post("/api/categorySelection", prompt);

    console.log(categoryResponse);

    if (
      categoryResponse.statusText === "OK" ||
      categoryResponse.status === 200
    ) {
      const formObject = {
        propertyId: properties[property].property_id,
        jobTitle: shortDesc,
        category: categoryResponse.data,
        jobDescription: longDesc,
        directAssigned: previousVendor,
        sendMarket: marketStatus,
        activityDesc: `Created job request "${shortDesc}.`,
        userName: `${user.first_name} ${user.last_name}`,
        taskArray: JSON.stringify(availabilityArray),
      };

      console.log(formObject, "JRCREATION!!!");

      axios.post("/jobRequest/newJobRequest", formObject).then((response) => {
        let jobId = response.data.jobId;
        if (response.data.ok) {
          if (attachments.length > 0) {
            let form = new FormData();

            for (let attachment of attachments) {
              form.append("file", attachment);
            }
            form.append("job_id", jobId);

            axios.post("/jobRequest/addImages", form).then((res) => {
              finishSubmit();
            });
          } else {
            finishSubmit();
          }
        }
      });
    }
  };

  const onUpdate = () => {
    const finishSubmit = () => {
      props.displaySubmitAlert();
      props.onClose();
    };
    const formObject = {
      jobTitle: shortDesc,
      category: category,
      jobDescription: longDesc,
      directAssigned: previousVendor,
      sendMarket: marketStatus,
      jobID: props.jrData.job_id,
    };

    axios.post("/jobRequest/updateJob", formObject).then((response) => {
      let jobId = props.jrData.job_id;
      if (response.data.ok) {
        if (attachments.length > 0) {
          let form = new FormData();

          for (let attachment of attachments) {
            form.append("file", attachment);
          }
          form.append("job_id", jobId);

          axios.post("/jobRequest/addImages", form).then((res) => {
            finishSubmit();
            props.fetchJrData();
            props.fetchData();
          });
        } else {
          finishSubmit();
          props.fetchJrData();
          props.fetchData();
        }
      }
    });
  };

  function importFile() {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
    };
    console.log(newArray, "NEWARRAYfgjkshgisoh");
    input.click();
  }

  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(newArray);
    }
  };

  const createAttachmentDisplay = (attachments, jrImages) => {
    console.log(attachments, "ATTACHMENTS IN DISPLAYngksbgjkhfb");
    const attachmentList = [];
    for (let i in attachments) {
      attachmentList.push(
        <div
          onClick={() => removeAttachment(i)}
          key={attachments[i].name + attachments[i].size}
          className="attachment"
        >
          {attachments[i].type.includes("image") ? (
            <img
              className="testIMG"
              src={URL.createObjectURL(attachments[i])}
              alt=""
            />
          ) : (
            attachments[i].name
          )}
        </div>
      );
    }

    return (
      <>
        <div>
          <p>Attachments: </p>
        </div>
        {attachmentList}
      </>
    );
  };

  const [directAssignList, setDirectAssignList] = useState([]);

  const fetchDirectAssignList = () => {
    axios.post("/jobRequest/getDirectAssignVendors").then((res) => {
      setDirectAssignList(res.data.vendorDetails);
    });
  };

  useEffect(() => {
    fetchDirectAssignList();
  }, []);

  const mobileView = useMediaQuery("(max-width: 600px)");

  const [marketOrDirect, setMarketOrDirect] = useState("sendToMarket");
  console.log(props.jrImages);
  return (
    <Modal
      destroyOnClose={true}
      width="700px"
      title="I need help with..."
      open={props.displayRequestForm}
      onCancel={props.onClose}
      footer={false}
    >
      <>
        <form style={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={1}>
            {!props.editForm && (
              <Grid item xs={12} md={12}>
                <Stack>
                  {/* <InputLabel>Property</InputLabel> */}
                  <Select
                    id="property"
                    name="property"
                    value={property}
                    onChange={(e) => {
                      setProperty(e.target.value);
                    }}
                  >
                    {/* <MenuItem value={'selectProperty'} hidden>
												Select Property...
											</MenuItem> */}
                    {properties.map((property, index) => (
                      <MenuItem key={property.property_id} value={index}>
                        {property.address}
                      </MenuItem>
                    ))}
                  </Select>
                  {formError && property === "selectProperty" && (
                    <Typography color="#FF0000">
                      * Please select Property.
                    </Typography>
                  )}
                </Stack>
              </Grid>
            )}

            {/* <Grid item xs={12} md={props.editForm ? 12 : 6}>
              <Stack>
                <InputLabel>Category</InputLabel>
                <Select
                  id="category"
                  name="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <MenuItem value={"selectCategory"} hidden>
                    Select Category
                  </MenuItem>
                  {GetServices().map((service) => (
                    <MenuItem
                      value={service.service_name}
                      key={service.service_id}
                    >
                      {service.service_name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid> */}

            <Grid item xs={12} md={12}>
              <Stack>
                {/* <InputLabel>Whats the Problem?</InputLabel> */}
                <OutlinedInput
                  required
                  id="shortDesc"
                  name="shortDesc"
                  value={shortDesc}
                  onChange={(e) => setShortDesc(e.target.value)}
                  placeholder="What is the issue?"
                />
              </Stack>
              {formError && !shortDesc && (
                <Typography color="#FF0000">* Required Field.</Typography>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack>
                {/* <InputLabel>Tell us more about your problem</InputLabel> */}
                <OutlinedInput
                  multiline
                  rows={5}
                  required
                  id="longDesc"
                  name="longDesc"
                  value={longDesc}
                  inputProps={{ maxLength: 500 }}
                  onChange={(e) => setLongDesc(e.target.value)}
                  placeholder={"Provide detailed information about the issue"}
                />
                <Typography
                  variant="p"
                  color={longDesc.length === 500 ? "red" : "black"}
                >
                  {longDesc.length}/500
                </Typography>
                {/* <TextArea
                    rows={5}
                    id="longDesc"
                    name="longDesc"
                    value={longDesc}
                    onChange={(e) => setLongDesc(e.target.value)}
                    maxLength={500}
                    placeholder={"Problem Description"}
                  /> */}
              </Stack>

              {formError && !longDesc && (
                <Typography color="#FF0000">* Required Field.</Typography>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack>
                {/* <InputLabel>
                  Please upload any pictures or documents or videos
                </InputLabel> */}
                <Button
                  size="sm"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={importFile}
                  id="document"
                  name="document"
                  fullWidth
                >
                  Add Pictures and Documents
                </Button>

                {props.jrImages && props.jrImages.length > 0 && (
                  <div className="attachments">
                    {props.jrImages.map((image, index) => (
                      <div
                        key={index}
                        className="attachment"
                        // onClick={() =>
                        //   deleteUploadedImage(image.jobrequest_images_id)
                        // }
                      >
                        <img key={index} src={image.image} alt="" />
                      </div>
                    ))}
                  </div>
                )}
                {attachments !== undefined && attachments.length !== 0 && (
                  <div className="attachments">
                    {createAttachmentDisplay(attachments)}
                  </div>
                )}
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {formError && marketStatus + assignStatus === 0 && (
                <Typography color="#FF0000">
                  * Choose how to assign your job request.
                </Typography>
              )}
              {directAssignList?.length > 0 && (
                <Stack>
                  <InputLabel>
                    Would you like to send this job to the market or directly
                    assign it to a vendor?
                  </InputLabel>
                  <RadioGroup name="radio-buttons-group">
                    <FormControlLabel
                      onClick={(e) => {
                        radioHandler(1);
                        radioHandlerAssign();
                        setMarketOrDirect("directAssign");
                      }}
                      value={"directAssign"}
                      control={<Radio />}
                      label="Direct Assign"
                      checked={marketOrDirect === "directAssign"}
                    />
                    {status === 1 && (
                      <Select
                        onChange={(e) => setPreviousVendor(e.target.value)}
                        value={previousVendor}
                        id="previousVendor"
                        name="previousVendor"
                      >
                        {directAssignList.map((vendors) => (
                          <MenuItem
                            value={vendors.vendor_id}
                            key={vendors.vendor_id}
                          >
                            {vendors.first_name + " " + vendors.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    <FormControlLabel
                      onClick={(e) => {
                        radioHandler(2);
                        radioHandlerMarket();
                        setMarketOrDirect("sendToMarket");
                      }}
                      value={"sendToMarket"}
                      control={<Radio />}
                      label="Send to Market"
                      checked={marketOrDirect === "sendToMarket"}
                    />
                  </RadioGroup>
                </Stack>
              )}
            </Grid>
            {!props.editForm && (
              <Grid item xs={12} md={12}>
                <Stack direction="column" width="100%" alignItems="center">
                  {formError && !availabilityArray && (
                    <Typography width="100%" color="#FF0000">
                      * Please provide the times that you are available.
                    </Typography>
                  )}
                  {/* <AnimateButton> */}
                  <Button
                    fullWidth
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAvailabilityModal(true);
                    }}
                  >
                    Pick a Time
                  </Button>
                  {/* </AnimateButton> */}
                </Stack>
              </Grid>
            )}

            <Grid item xs={12}>
              <Stack
                direction="row"
                width="100%"
                justifyContent={mobileView ? "center" : "flex-end"}
              >
                {submitting && (
                  <Typography width="100%" color="#767676">
                    {!props.editForm ? "Submitting..." : "Updating..."}
                  </Typography>
                )}
                <AnimateButton>
                  <Button
                    disabled={submitting}
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      props.onClose();
                    }}
                    sx={{ ml: 2, float: mobileView ? "none" : "right" }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button
                    disabled={submitting}
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      checkSubmit();
                      console.log("UPDATECLICKED");
                    }}
                    sx={{ ml: 2, float: mobileView ? "none" : "right" }}
                  >
                    {!props.editForm ? "Submit" : "Update"}
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
        {availabilityModal && (
          <SetClientAvailability
            jrData={props.jrData}
            onClose={() => setAvailabilityModal(false)}
            setAvailabilityArray={setAvailabilityArray}
            availabilityArray={availabilityArray}
          />
        )}
      </>
    </Modal>
  );
}

JobRequest.defaultProps = {
  property: "selectProperty",
  category: "selectCategory",
  shortDesc: "",
  longDesc: "",
  previousVendor: "",
};

export default JobRequest;
