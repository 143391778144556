import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import Highlighter from "./third-party/Highlighter";
import TopBar from "../../Pages/MarketPage/MarketComponents/TopBar";

// header style
const headerSX = {
  p: 2,
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentSX = { padding: "0" },
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      codeHighlight,
      market,
      marketBar,
      isMarket,
      isMobile,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;

    const mobileView = useMediaQuery("(max-width: 600px)");

    return (
      <Card
        elevation={elevation || 0}
        id="main_card"
        ref={ref}
        {...others}
        sx={{
          ...sx,

          border: border ? "1px solid" : "none",
          borderRadius: 2,
          // marginTop: 3,
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.divider
              : theme.palette.grey.A800,
          boxShadow:
            boxShadow && (!border || theme.palette.mode === "dark")
              ? shadow(theme) || theme.customShadows.z1
              : "inherit",
          ":hover": {
            boxShadow: boxShadow
              ? shadow(theme) || theme.customShadows.z1
              : "inherit",
          },
          "& pre": {
            m: 0,
            p: "16px !important",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <Grid
            item
            sx={headerSX}
            style={{
              paddingBottom: mobileView ? "0" : "20px",
              marginBottom: `${
                mobileView
                  ? !market
                    ? "0"
                    : isMarket && marketBar.mapOpen
                    ? "0"
                    : !market
                    ? "3rem"
                    : isMarket && !marketBar.mapOpen && "2rem"
                  : "0"
              }`,
            }}
          >
            {/* <h3 style={{ textAlign: "center", fontWeight: "bold" }}>{title}</h3> */}
            {market && (
              <TopBar
                setCurrentJRs={marketBar.setCurrentJRs}
                user={marketBar.user}
                filterCategory={marketBar.filterCategory}
                startDate={marketBar.startDate}
                endDate={marketBar.endDate}
                mapEmployees={marketBar.mapEmployees}
                isMarket={isMarket}
                isMobile={isMobile}
                mapOpen={marketBar.mapOpen}
                toJR={marketBar.toJR}
                toMap={marketBar.toMap}
                setStartDate={marketBar.setStartDate}
                setEndDate={marketBar.setEndDate}
                setFilterCategory={marketBar.setFilterCategory}
                setMapEmployees={marketBar.setMapEmployees}
                refreshMap={marketBar.refreshMap}
                setIsMarket={marketBar.setIsMarket}
              />
            )}
          </Grid>
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={<Typography variant="h3">{title}</Typography>}
            action={secondary}
          />
        )}

        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}

        {/* card footer - clipboard & highlighter  */}
        {codeHighlight && (
          <>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Highlighter codeHighlight={codeHighlight} main>
              {children}
            </Highlighter>
          </>
        )}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.node,
  shadow: PropTypes.func,
  sx: PropTypes.object,
  title: PropTypes.string,
  codeHighlight: PropTypes.bool,
  content: PropTypes.bool,
  children: PropTypes.node,
  market: PropTypes.bool,
};

MainCard.defaultProps = {
  market: false,
};

export default MainCard;
