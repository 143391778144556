import PhoneInput from "../../Components/FormComponents/PhoneInput";
import DatePicker from "../../Components/FormComponents/DatePicker";

import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";

import AnimateButton from "../../Layout/components/@extended/AnimateButton";
import { useUserState } from "../../Context/UserContext";
import GetServices from "../commonComponents/API/GetServices";

const EditUserProfile = (props) => {
  const { user } = useUserState();
  const isVendor = props.user.vendorMode;

  const isEmployee =
    props.user.vendorMode && props.user.empRole === ("Admin" || "Manager");

  console.log(user);

  const onSubmit = (values) => {
    console.log(props);
    const DOB = new Date(values.dob).toISOString();
    const updateObject = {
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      DOB: DOB,
      // Gender: values.gender,
      UserType: "client",
      Phone: values.phone,
      mainAddress: values.mainAddress,
      vendorLegalName: "",
      vendorDbaName: "",
      vendorServices: "",
    };

    if (isVendor && !isEmployee) {
      updateObject.vendorLegalName = values.vendorLegalName;
      updateObject.vendorDbaName = values.vendorDbaName;
      updateObject.vendorServices = values.vendorServices;
      updateObject.UserType = "vendor";
    }

    for (let key of Object.keys(updateObject)) {
      if (updateObject[key] == null) {
        console.error("ERROR:", key, "is missing");
      }
    }
    console.log(user);
    axios
      .post("/updateUserDetails", updateObject)
      .then((res) => {
        if (res.data.ok) {
          props.closeUpdate();
          //   props.fetchVendorData();
          props.fetchClientData();
          props.fetchEmployeeData();
          props.fetchProperties();
          console.log(user);
          props.setUserFirstName(values.firstName);
          props.setUserLastName(values.lastName);
          props.setUserEmail(values.email);
          props.setUserPhoneNum(values.phone);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const mainAddress =
    props.propertyList.length > 0 ? props.propertyList[0].property_id : 0;

  const initialValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phone: user.phone_number,
    dob: user.dob,
    // gender: user.gender,
    mainAddress: mainAddress,
    vendorLegalName: null,
    vendorDbaName: null,
    vendorServices: null,
  };
  const validationSchema = {
    firstName: Yup.string().max(45).required("First Name is missing"),
    lastName: Yup.string().max(45).required("Last Name is missing"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    phone: Yup.string().min(15, "phoneNumber is required").max(255),
    dob: Yup.date().nullable(),
    // gender: Yup.string().matches(/[MFO]/).max(1).required("gender is required"),
    vendorLegalName: null,
    vendorDbaName: null,
    vendorServices: null,
  };

  if (isVendor) {
    initialValues.vendorLegalName = props.user.vendor_legal_name;
    initialValues.vendorDbaName = props.user.vendor_dba_name;
    initialValues.vendorServices = props.user.vendor_services;

    validationSchema.vendorLegalName = Yup.string()
      .max(255)
      .required("Vendor Legal Name is required");
    validationSchema.vendorDbaName = Yup.string()
      .max(255)
      .required("Vendor dba Name is required");
    validationSchema.vendorServices = Yup.string()
      .max(255)
      .required("Vendor Services is required");
  }

  //   useEffect(() => {
  //     props.setUserFirstName(values.firstName);
  //     props.setUserLastName(values.lastName);
  //     props.setUserEmail(values.email);
  //     props.setUserPhoneNum(values.phone);
  //     // props.setUserStreet()
  //     // props.setUserProvince()
  //     // props.setUserPostal()
  //   }, [values.firstName, values, lastName, values.email, values.phone]);
  return (
    <div id="update-user-form">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          values,
        }) => (
          <form action="#update-user-form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>First Name*</InputLabel>
                  <OutlinedInput
                    id="firstName-signup"
                    type="firstName"
                    value={values.firstName}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  {touched.firstName && errors.firstName && (
                    <FormHelperText error id="helper-text-firstName-signup">
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Last Name*</InputLabel>
                  <OutlinedInput
                    id="lastName-signup"
                    type="lastName"
                    value={values.lastName}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  {touched.lastName && errors.lastName && (
                    <FormHelperText error id="helper-text-lastName-signup">
                      {errors.lastName}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Phone</InputLabel>
                  <PhoneInput
                    name="phone"
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phone && errors.phone && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.phone}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Date of Birth</InputLabel>
                  <DatePicker
                    required
                    type="date"
                    name="dob"
                    value={values.dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {console.log("dob", values.dob, user.dob)}
                  {touched.dob && errors.dob && (
                    <Typography noWrap variant="h8" color="#ff0000">
                      *{errors.dob}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    id="gender-signup"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                  >
                    <MenuItem value={"M"}>Male</MenuItem>
                    <MenuItem value={"F"}>Female</MenuItem>
                    <MenuItem value={"O"}>Other</MenuItem>
                  </Select>
                </Stack>
              </Grid> */}
              {isVendor && !isEmployee && (
                <>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <InputLabel>Vendor Legal Name</InputLabel>
                      <OutlinedInput
                        id="vendorLegalName"
                        type="vendorLegalName"
                        value={values.vendorLegalName}
                        name="vendorLegalName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Vendor Legal Name"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <InputLabel>Vendor dba Name</InputLabel>
                      <OutlinedInput
                        id="vendorDbaName-signup"
                        type="vendorDbaName"
                        value={values.vendorDbaName}
                        name="vendorDbaName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Vendor dba Name"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <Stack>
                        <InputLabel>Provided Service</InputLabel>
                        <Select
                          style={{ marginTop: "0.5rem" }}
                          required
                          id="services"
                          name="services"
                          value={values.vendorServices}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {GetServices().map((service) => (
                            <MenuItem
                              value={service.service_name}
                              key={service.service_id}
                            >
                              {service.service_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Email</InputLabel>
                  <OutlinedInput
                    id="postalCode-signup"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Email"
                    disabled
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="helper-text-email-signup">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box
                        sx={{
                          width: 85,
                          height: 8,
                          borderRadius: "7px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel>Main Address</InputLabel>
                  <Select
                    id="address_selector"
                    name="mainAddress"
                    value={values.mainAddress}
                    onChange={handleChange}
                  >
                    {props.propertyList.map((property) => {
                      console.log(property);
                      const unitNumber =
                        property.unit_number !== null
                          ? ` Unit:${property.unit_number}`
                          : "";
                      return (
                        <MenuItem value={property.property_id}>
                          {property.street}
                          {unitNumber}, {property.city}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Stack>
              </Grid>

              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    onClick={handleSubmit}
                    size="sm"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ ml: 2, float: "right" }}
                  >
                    Update
                  </Button>
                  <Button
                    disableElevation
                    size="sm"
                    variant="outlined"
                    color="secondary"
                    onClick={props.closeUpdate}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditUserProfile;
