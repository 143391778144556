import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import AnimateButton from "../../Layout/components/@extended/AnimateButton";

const SaveCardForm = ({ onSuccess, successUrl }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: successUrl, // Pass the return_url (redirect after success)
      },
    });

    if (error) {
      setErrorMessage(error.message); // Show the error message to the user
    } else {
      // Trigger the success function passed via props
      if (onSuccess) {
        onSuccess(); // Call the function passed as prop
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <AnimateButton>
        <Button
          size="sm"
          type="submit"
          variant="contained"
          color="primary"
          sx={{ ml: 2, float: "right", marginBottom: "1em" }}
          disabled={!stripe || !elements}
        >
          Submit
        </Button>
      </AnimateButton>

      {/* Show error message */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

SaveCardForm.defaultProps = {
  successUrl: window.location.origin + "/dashboard/profile", // Default success URL
};

export default SaveCardForm;
