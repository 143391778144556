import React, { useState } from "react";
import {
  Grid,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  OutlinedInput,
  Button,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import SetClientAvailability from "../../commonComponents/JRComponents/Availability/SetClientAvailability";
import { set } from "lodash";
import LoadingSpinner from "../../../Layout/components/LoadingSpinner";
import LoadScreen from "../../../Components/LoadScreen";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import StarRating from "../../commonComponents/JRComponents/Review/StarRating";

const JobRequestForm = () => {
  const [attachments, setAttachments] = useState([]);
  const [issue, setIssue] = useState("");
  const [details, setDetails] = useState("");
  const [city, setCity] = useState("");
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [availabilityArray, setAvailabilityArray] = useState(false);
  const user = { vendorMode: false };
  const [errorMessage, setErrorMessage] = useState("");
  const [expandedReview, setExpandedReview] = useState("");

  const [compatableVendors, setCompatableVendors] = useState([]);
  const [category, setCategory] = useState("");
  const [sending, setSending] = useState(false);
  const [fetchingVendors, setFetchingVendors] = useState(false);

  const location = useLocation();
  let navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFetchingVendors(true);

    if (!issue) {
      setErrorMessage("An issue is required to get help.");
      return;
    }
    if (!details) {
      setErrorMessage("Issue details are required to get help.");
      return;
    }
    if (!city) {
      setErrorMessage("City is required.");
      return;
    }

    const prompt = {
      prompt: details,
    };

    await axios
      .post("/api/getCompatableVendors", {
        prompt: prompt,
        city: city,
      })
      .then((res) => {
        console.log(res.data);
        setCompatableVendors(res.data.vendors);
        setCategory(res.data.category);
        setFetchingVendors(false);
      });
  };

  const handleGetQuote = async () => {
    if (!issue) {
      setErrorMessage("An issue is required.");
      return;
    }
    if (!details) {
      setErrorMessage("Issue details are required.");
      return;
    }
    if (!city) {
      setErrorMessage("A city is required.");
      return;
    }
    setSending(true);

    const prompt = {
      prompt: details,
    };
    console.log({ prompt: details });

    const categoryResponse = await axios.post("/api/categorySelection", prompt);

    console.log(categoryResponse);

    if (
      categoryResponse.statusText === "OK" ||
      categoryResponse.status === 200
    ) {
      const date = new Date();

      const days = ["sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const day = days[date.getDay()];
      const month = months[date.getMonth()];
      const dayOfMonth = date.getDate();

      const periods = ["Morning", "Afternoon", "Evening"];
      const result = periods.map(
        (period) => `${day}, ${month} ${dayOfMonth} ${period}`
      );
      console.log(JSON.stringify(result));

      const jobData = {
        category: categoryResponse.data,
        jobTitle: issue,
        jobDescription: details,
        sendMarket: 1,
        taskArray: JSON.stringify(result),
        activityDesc: `Created job request "${issue}.`,
      };

      navigate("/signup", {
        state: { jobData: jobData },
      });
      setIssue("");
      setDetails("");
      setCity("");
    }
  };

  //   const handleSubmit = async (event) => {
  //     console.log(event);
  //     event.preventDefault();

  //     if (!issue) {
  //       setErrorMessage("An issue is required.");
  //       return;
  //     }
  //     if (!details) {
  //       setErrorMessage("Issue details are required.");
  //       return;
  //     }

  //     setSending(true);

  //     const prompt = {
  //       prompt: details,
  //     };
  //     console.log({ prompt: details });

  //     const categoryResponse = await axios.post(
  //       "/jobRequest/categorySelection",
  //       prompt
  //     );

  //     console.log(categoryResponse);

  //     if (
  //       categoryResponse.statusText === "OK" ||
  //       categoryResponse.status === 200
  //     ) {
  //       const date = new Date();

  //       const days = ["sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //       const months = [
  //         "Jan",
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep",
  //         "Oct",
  //         "Nov",
  //         "Dec",
  //       ];

  //       const day = days[date.getDay()];
  //       const month = months[date.getMonth()];
  //       const dayOfMonth = date.getDate();

  //       const periods = ["Morning", "Afternoon", "Evening"];
  //       const result = periods.map(
  //         (period) => `${day}, ${month} ${dayOfMonth} ${period}`
  //       );
  //       console.log(JSON.stringify(result));

  //       const jobData = {
  //         city,
  //         category: categoryResponse.data,
  //         jobTitle: issue,
  //         jobDescription: details,
  //         sendMarket: 1,
  //         taskArray: JSON.stringify(result),
  //         activityDesc: `Created job request "${issue}.`,
  //       };

  //       navigate("/signup", {
  //         state: { jobData: jobData, attachments: attachments },
  //       });
  //       setIssue("");
  //       setDetails("");
  //       setCity("");
  //       setAttachments([]);
  //     }
  //   };

  const mobileView = useMediaQuery("(max-width:600px)");
  const mediumScreen = useMediaQuery("(max-width:1150px)");

  return !sending ? (
    <>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit}
      >
        <Stack
          style={{ height: "100vh" }}
          padding={
            mobileView ? "0" : mediumScreen ? "7.5rem 7.5rem" : "7.5rem 22.5rem"
          }
          height="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            spacing={2}
            border={!mobileView ? "1px solid lightgray" : "none"}
            borderRadius={2}
            padding={"2.5rem"}
          >
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                sx={{ mb: { xs: -0.5, sm: 0.5 } }}
              >
                <Typography variant="h3">I need help with...</Typography>
              </Stack>
            </Grid>

            {/* Issue Input */}
            <Grid item xs={12}>
              <Stack>
                {/* <InputLabel>What is the Issue?</InputLabel> */}
                <OutlinedInput
                  id="issue"
                  name="issue"
                  value={issue}
                  onChange={(e) => {
                    setIssue(e.target.value);
                    setErrorMessage("");
                  }}
                  placeholder="What is the issue?"
                />
              </Stack>
            </Grid>

            {/* Details Input */}
            <Grid item xs={12}>
              <Stack>
                {/* <InputLabel>Describe the Problem in Detail</InputLabel> */}
                <OutlinedInput
                  id="details"
                  name="details"
                  multiline
                  rows={6}
                  value={details}
                  onChange={(e) => {
                    setDetails(e.target.value);
                    setErrorMessage("");
                  }}
                  placeholder="Provide detailed information about the issue"
                />
              </Stack>
            </Grid>

            {/* City Input */}
            <Grid item xs={12} md={12}>
              <GooglePlacesAutocomplete
                className={"css-1hb7zxy-IndicatorsContainer"}
                apiKey={process.env.GOOGLE_API_KEYS}
                autocompletionRequest={{
                  componentRestrictions: { country: "ca" }, // Restrict to Canada (CA)
                  types: ["(cities)"], // Use cities type to fetch city-level results
                }}
                selectProps={{
                  name: "City",
                  value: city ? { label: city, value: city } : null, // Bind to the current value of `city`
                  onChange: (selectedOption) => {
                    console.log(selectedOption);

                    // Parse city and province from the selected option
                    const [city, province] = selectedOption.label.split(", ");

                    // Update Formik values
                    setCity(city);
                  },
                  placeholder: "Search for a city", // Customize placeholder text
                }}
              />
              <style>
                {`
          .css-1hb7zxy-IndicatorsContainer {
            display: none; // Hide dropdown icon
          }
        `}
              </style>
            </Grid>

            {/* Help me Button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Help Me!
              </Button>
            </Grid>

            {/* Compatable Vendor Display */}
            <Grid item xs={12} sx={{}}>
              {fetchingVendors && (
                <Stack
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadScreen small={true} />
                </Stack>
              )}
              {compatableVendors.length > 0 && (
                <Stack>
                  <Typography variant="h5">
                    Here are some vetted vendors that can help you:
                  </Typography>
                  <Stack
                    sx={{
                      overflowY: compatableVendors.length > 4 ? "scroll" : "",
                      maxHeight: compatableVendors.length > 4 ? "12.5rem" : "",
                    }}
                  >
                    {compatableVendors.map((vendor) => (
                      <Stack
                        sx={{
                          //   backgroundColor: "pink",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "100%",
                          marginBottom: "0.35rem",
                          //   border: "1px solid black",
                          boxShadow: "0px 0px 36px -20px rgba(0,0,0,1)",
                          padding: "0.35rem",
                          "&:hover": {
                            backgroundColor: "rgba(0,0,0,0.05)",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          expandedReview === vendor.id
                            ? setExpandedReview("")
                            : setExpandedReview(vendor.id);
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            minWidth: "100%",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "1rem", fontWeight: 600 }}
                          >{`${vendor.display_name} - ${category}`}</Typography>
                          <StarRating
                            rating={vendor.average_review}
                            size={"0.15rem"}
                          />
                        </Stack>
                        {expandedReview === vendor.id &&
                          (vendor.average_review !== "N/A" ? (
                            <>
                              <Typography>
                                {vendor.best_review_comment
                                  ? vendor.best_review_comment
                                  : "No Review Data to display"}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography>No Review Data to display</Typography>
                            </>
                          ))}
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              )}
            </Grid>

            {errorMessage !== "" && (
              <Grid item xs={12}>
                <Typography variant="body1" color="error">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            {/* Submit Button */}
            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
              <Button
                onClick={handleGetQuote}
                // type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "16px" }}
              >
                Get a quote!
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </>
  ) : (
    <LoadScreen />
  );
};

const NeedHelp = () => {
  return <JobRequestForm />;
};

export default NeedHelp;
