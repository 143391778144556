// material-ui
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import "./styles.css";

import "../../../../Pages/commonComponents/CSS/styles.css";

import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import FeedbackForm from "../../../../Pages/commonComponents/FeedbackForm";
import { useUserDispatch, useUserState } from "../../../../Context/UserContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import AnimateButton from "../../../components/@extended/AnimateButton";

import TermsAndConditions from "../../../../Pages/PDF/TermsViewer";
import PrivacyPolicy from "../../../../Pages/PDF/PrivacyViewer";
import {
  CloseOutlined,
  DownOutlined,
  PictureOutlined,
  SendOutlined,
  UpOutlined,
} from "@ant-design/icons";
import ChatContainer from "../../../../Components/ChatContainer";

import FixxieAvatar from "../../../../images/Fixxie_Avatar.png";
import { parseJobRequest } from "../../../../parser/parseJobRequest";
import parseSubmitBidMessage from "../../../../parser/parseBidRequest";
import { useJRContext } from "../../../../Context/JobRequestContext";
import ReferralModal from "../../../../Pages/commonComponents/Referrral/ReferralModal";
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery("(max-width: 900px)");
  const [feedbackModal, setFeedbackModal] = useState(false);
  const dispatch = useUserDispatch();
  const { user } = useUserState();

  const [fixbotModal, setFixbotModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [messages, setMessages] = useState([
    {
      user: "Fixxie",
      message: `Hello ${user.first_name}! How can i assist you today?`,
    },
  ]);
  const [stopTyping, setStopTyping] = useState(false);
  const [thread, setThread] = useState();

  const [vendorDescisionModal, setVendorDescisionModal] = useState(false);
  const [companyIntake, setCompanyIntake] = useState(false);
  const [values, setValues] = useState();
  const [showNextPage, setShowNextPage] = useState(false);

  const navigate = useNavigate();
  let location = useLocation();
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [activeCreateAccount, setActiveCreateAccount] = useState(false);
  const [termsIsRead, setTermsIsRead] = useState(false);
  const [policyIsRead, setPolicyIsRead] = useState(false);
  const [termsReadError, setTermsReadError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [clientID, setClientID] = useState();
  const [locationNotFound, setLocationNotFound] = useState(false);
  const [companyEmail, setCompanyEmail] = useState();
  const [companyLegalName, setCompanyLegalName] = useState();
  const [companyDbaName, setCompanyDbaName] = useState();
  const [businessNumber, setBusinessNumber] = useState();
  const [taxId, setTaxId] = useState();
  const [selectedServices, setSelectedServices] = useState([]);
  const [institutionCode, setInstitutionCode] = useState("");
  const [transitCode, setTransitCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [properties, setProperties] = useState();
  const [property, setProperty] = useState();
  const [previousVendors, setPreviousVendors] = useState();

  const [attachments, setAttachments] = useState([]);

  const [JRs, setJRs] = useState([]);
  const [activeJRs, setActiveJRs] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [isModalExpanded, setIsModalExpanded] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(true);

  const { openJR, setOpenJR } = useJRContext();
  const [currentJR, setCurrentJR] = useState(null);

  const [stopAccepted, setStopAccepted] = useState(false);

  const [referralModal, setReferrralmodal] = useState(false);
  // const [JRs, setJRs] = useState(false);
  const [referAlert, setReferAlert] = useState(false);

  const getJRData = useCallback(() => {
    axios.get("/jobRequest/getClientJRs").then((res) => {
      console.log(res.data);
      setJRs(res.data);
      window.JRList = res.data;
    });
  }, []);

  const displayReferSuccess = () => {
    getJRData();
    setReferAlert(true);
    setTimeout(() => setReferAlert(false), 4000);
  };

  const openFixxieJR = async (num) => {
    if (window.currentOpenJR === num) {
      setOpenJR(null);
      window.currentOpenJR = null;
      console.log("=NUM");
    } else {
      const jr = window.JRList.find((job) => job.job_id === num);
      setOpenJR(jr);
      window.currentOpenJR = num;
      console.log("!=NUM");
    }
  };

  useEffect(() => {
    window.openFixxieJR = openFixxieJR;
    window.currentOpenJR = null;
  }, []);

  useEffect(() => {
    const generateThread = async () => {
      const thread = await axios.post("/fixbotChat/generateThread");
      setThread(thread);
    };

    generateThread();
  }, []);

  useEffect(() => {
    const fetchDirectAssignList = () => {
      axios.post("/jobRequest/getDirectAssignVendors").then((res) => {
        setPreviousVendors(res.data.vendorDetails);
      });
    };

    fetchDirectAssignList();
  }, []);

  useEffect(() => {
    axios.get("/property/getPropertyDetails").then((res) => {
      console.log(res.data.property, "PROPERTIESSSSSSSSS");
      setProperties(res.data.property);
      res.data.property.forEach((prop, index) => {
        if (user.street === prop.street && user.city === prop.city) {
          console.log("Property Index", index);
          setProperty(index);
        }
      });
    });
  }, []);

  useEffect(() => {
    const fetchJRData = () => {
      if (user.vendorMode === true) {
        axios.get("/jobRequest/getMarketJRdetails").then((res) => {
          let jobDetails = res.data.jobDetails.map((JR) => {
            JR.job_due = new Date(JR.job_due);
            return JR;
          });
          console.log(jobDetails, "JOBDETAILS123456");
          // window.JRList = jobDetails;
          setJRs(jobDetails);
        });
      } else {
        axios.get("/jobRequest/getClientJRs").then((res) => {
          console.log(res.data, "CLIENT JOB DETAILS123456");
          // window.JRList = res.data;
          setJRs(res.data);
        });
      }
    };
    const fetchAcceptedData = () => {
      axios.post("/jobRequest/getAcceptedJRdetails").then((res) => {
        let jobDetails = res.data.jobDetails.map((JR) => {
          JR.job_due = new Date(JR.job_due);
          return JR;
        });
        setActiveJRs(jobDetails);
        // window.JRList = jobDetails;
      });
    };
    fetchAcceptedData();
    fetchJRData();
    console.log(JRs);
    console.log(activeJRs, "ACTIVE JRRSSSSHIKBIBI");
  }, [user.vendorMode]);

  useEffect(() => {
    // console.log("SETTING WINDOW JRS");
    // console.log(JRs, "JRS");
    // console.log(activeJRs, "activeJRs");
    // console.log([...JRs, ...activeJRs]);
    console.log(window.JRList, "window.JRList");
    window.JRList = [...JRs, ...activeJRs];
    window.attachments = [];
    console.log(window.JRList, "window.JRList");
    // console.log([...JRs, ...activeJRs]);
    // console.log(activeJRs, "activeJRs");
    // console.log(JRs, "JRS");
    // console.log("SETTING WINDOW JRS");
  }, [JRs, activeJRs]);

  useEffect(() => {
    const fetchEmployeeData = () => {
      axios
        .post("/employee/getEmployees", { VendorId: user.vendor_id })
        .then(({ data }) => {
          const employees = data.employees;
          console.log(employees, "EMPLOYEE DETAILS123456");
          setEmployees(employees);
        });
    };

    fetchEmployeeData();
  }, [user.vendorMode]);

  const handleImageClick = () => {
    let newArray;
    if (attachments !== undefined) {
      newArray = [...attachments];
    } else {
      newArray = [];
    }
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      newArray.push(...input.files);
      setAttachments(2);
      setAttachments(newArray);
      window.attachments = newArray;
    };
    input.click();
  };

  const removeAttachment = (id) => {
    const newArray = [...attachments];
    newArray.splice(id, 1);
    if (newArray.length === 0) {
      setAttachments("");
    } else {
      setAttachments(newArray);
    }
  };

  const [abortController, setAbortController] = useState(null);

  const handleSendClick = async () => {
    setSending(true);
    setStopAccepted(true);

    const controller = new AbortController();
    setAbortController(controller);

    setMessages([
      ...messages,
      {
        user: user.first_name,
        message: userMessage,
        attachments: attachments.map((file) => URL.createObjectURL(file)),
      },
    ]);
    setBidList([]);

    try {
      const response = await axios.post(
        "/fixbotChat/sendMessage",
        {
          userMessage,
          user,
          threadId: thread.data.threadId,
          properties: JSON.stringify(properties),
          marketJRs: JSON.stringify(JRs),
          host: window.location.host,
          bidList: bidList,
          employees: employees,
          activeJRs: activeJRs,
        },
        { signal: controller.signal }
      );

      console.log(response, "RESPONSEEE");

      if (response.statusText === "OK" || response.status === 200) {
        console.log(response.data, "RESPONSEEEEEEE");
        console.log(response.data.includes("viewEvents"));
        const test = "viewEvents";
        console.log(test.includes("viewEvents"));
        // Use a switch-case to check for one specific string in response.data
        switch (true) {
          case response.data.includes("JR Collection"):
            const jrImages =
              window.attachments.length > 0 ? window.attachments : [];
            // Perform action for the specific string
            const parsedJRData = parseJobRequest(response.data);
            console.log(parsedJRData);

            setMessages([
              ...messages,
              {
                user: user.first_name,
                message: userMessage,
                attachments: attachments.map((file) =>
                  URL.createObjectURL(file)
                ),
              },
              {
                user: "Fixxie",
                message:
                  "Perfect! I’ll submit your job request now. You’ll hear back from vendors soon. Let me know if there’s anything else I can assist you with!",
              },
            ]);

            const formObject = {
              propertyId: parseInt(parsedJRData.propertyId),
              jobTitle: parsedJRData.title,
              category: parsedJRData.category,
              jobDescription: parsedJRData.description,
              directAssigned:
                parsedJRData.sendMarket === "market"
                  ? ""
                  : parsedJRData.sendMarket,
              sendMarket: parsedJRData.sendMarket === "market" ? 1 : 0,
              activityDesc: `Created job request "${parsedJRData.title}".`,
              userName: `${user.first_name} ${user.last_name}`,
              taskArray: parsedJRData.availability,
            };

            console.log(formObject, "FORMOBJECTPARSEDJR");

            axios
              .post("/jobRequest/newJobRequest", formObject)
              .then((response) => {
                let jobId = response.data.jobId;
                if (response.data.ok) {
                  if (jrImages.length > 0) {
                    let form = new FormData();
                    for (let attachment of jrImages) {
                      form.append("file", attachment);
                    }
                    form.append("job_id", jobId);
                    axios.post("/jobRequest/addImages", form).then((res) => {
                      console.log("COMPLETE");
                      setAttachments([]);
                      window.attachments = [];
                    });
                  }
                }
              });

            break;

          case response.data.includes("viewSpecificJR"):
            console.log(user, "USERVENDORVIEWSPECIFIC");
            function parseJobDetails(message) {
              const idMatch = message.match(/Job ID: (.+)/);
              const titleMatch = message.match(/Job Title: (.+)/);
              const descriptionMatch = message.match(
                /Job Description:([\s\S]+?)Requester ID:/
              );
              const requesterMatch = message.match(/Requester ID: (.+)/);

              return {
                jobID: idMatch ? parseInt(idMatch[1], 10) : null,
                jobTitle: titleMatch ? titleMatch[1].trim() : null,
                jobDescription: descriptionMatch
                  ? descriptionMatch[1].trim()
                  : null,
                requesterID: requesterMatch
                  ? parseInt(requesterMatch[1], 10)
                  : null,
              };
            }

            const parsedDetails = parseJobDetails(response.data);
            console.log(parsedDetails, "Parsed Details");

            const jrDetails = await axios.post(
              "/jobRequest/getVendorExpandedDetails",
              {
                JobId: parsedDetails.jobID,
                vendorId: user.vendorId,
                clientId: parsedDetails.requesterID,
              }
            );

            console.log(jrDetails.data.jrImages, "JR Images");
            console.log(
              jrDetails.data.jrImages.map((file) => file.imageurl),
              "FILEURLHDKFAHGKFAGB"
            );

            setMessages([
              ...messages,
              {
                user: user.first_name,
                message: userMessage,
                attachments: attachments.map((file) =>
                  URL.createObjectURL(file)
                ),
              },
              {
                user: "Fixxie",
                message:
                  response.data !== ""
                    ? `Here's the information on Job ${parsedDetails.jobID}: ${parsedDetails.jobTitle}\n\n${parsedDetails.jobDescription}\n\n`
                    : "Sorry we could not complete your request at this time.\nPlease try again later.",
                attachments: jrDetails.data.jrImages.map((file) => file.image),
              },
            ]);

            break;

          case response.data.includes("submitBid"):
            function parseBidMessage(message) {
              message = message.replace(/[\n\r]/g, "");
              // Find the start and end of the JSON-like object
              const jsonStart = message.indexOf("{");
              const jsonEnd = message.lastIndexOf("}") + 1;

              // Extract the JSON part of the message
              const jsonString = message.substring(jsonStart, jsonEnd);
              console.log(jsonString, "JSONSTRING");

              return jsonString;
            }

            const bidDetails = parseBidMessage(response.data);
            const bidFormData = JSON.parse(bidDetails);
            console.log(JSON.parse(bidDetails), "BID DETAILS PARSED");

            axios.post("/bidding/startBidding", bidFormData).then((res) => {
              console.log("Bid Submitted");
              setMessages([
                ...messages,
                {
                  user: user.first_name,
                  message: userMessage,
                  attachments: attachments.map((file) =>
                    URL.createObjectURL(file)
                  ),
                },
                {
                  user: "Fixxie",
                  message:
                    response.data !== ""
                      ? `Your bid has been submitted. When the client makes a decision you will be notified.`
                      : "Sorry we could not complete your request at this time.\nPlease try again later.",
                },
              ]);
            });

            break;

          case response.data.includes("viewBids"):
            function extractJobId(message) {
              const match = message.match(/viewBids JobID: (\d+)/); // Use regex to find "JobID: {number}"
              if (match && match[1]) {
                return parseInt(match[1], 10); // Convert the matched JobID to a number
              }
              throw new Error(
                "Invalid message format. Could not extract JobID."
              );
            }

            const jobId = extractJobId(response.data);
            console.log(jobId, "JOB ID From VIew BIDSSSSS MEssage");

            axios.post("/bidding/getBids", { jobId: jobId }).then((res) => {
              console.log("viewing Bids");
              console.log(res.data.bidDetails);
              console.log(
                `Here are all the Bids for ${jobId}:\n\n${res.data.bidDetails.map(
                  (bid, index) =>
                    `${index + 1}. ${
                      bid.vendor_legal_name
                    }\nTotal Amount: $${bid.bid_amount.toFixed(2)}`
                )}`
              );
              setBidList(res.data.bidDetails);

              setMessages([
                ...messages,
                {
                  user: user.first_name,
                  message: userMessage,
                  attachments: attachments.map((file) =>
                    URL.createObjectURL(file)
                  ),
                },
                {
                  user: "Fixxie",
                  message:
                    response.data !== ""
                      ? `Here are all the Bids for <b>JobID: ${jobId}</b>:<br /><br />${res.data.bidDetails
                          .map(
                            (bid, index) =>
                              `<b>${index + 1}. ${
                                bid.vendor_legal_name
                              }<br />Labour Type:</b> ${
                                bid.estimated_hours > 0 ? "Hourly" : "Flat"
                              }<br />${
                                bid.estimated_hours > 0
                                  ? `<b>Estimated Hours:</b> ${
                                      bid.estimated_hours
                                    }<br /><b>Price per hour:</b> $${(
                                      bid.labour_charge / bid.estimated_hours
                                    ).toFixed(2)}<br />${
                                      bid.estimated_materials
                                        ? `<b>Material Price:</b> ${bid.estimated_materials}`
                                        : ""
                                    }<br /><br />`
                                  : ""
                              }<b>Total Amount:</b> $${bid.bid_amount.toFixed(
                                2
                              )}<br /><br /><b>Work Needed:</b> ${
                                bid.work_to_be_done
                              }<br /><br /><b>Extra Notes:</b> ${
                                bid.notes
                              }<br /><br />`
                          )
                          .join(
                            "<br />"
                          )} Let me know if you’d like to accept any of these bids!`
                      : "Sorry we could not complete your request at this time.\nPlease try again later.",
                },
              ]);
            });

            break;

          case response.data.includes("acceptBid"):
            function extractObjectFromMessage(message) {
              try {
                // Remove all newline characters from the message
                const cleanedMessage = message.replace(/\n/g, "");

                // Find the opening and closing braces for the object
                const objectStart = cleanedMessage.indexOf("{");
                const objectEnd = cleanedMessage.lastIndexOf("}");

                // Extract the substring that contains the object
                if (objectStart === -1 || objectEnd === -1) {
                  throw new Error("No object found in the message.");
                }

                const jsonString = cleanedMessage.slice(
                  objectStart,
                  objectEnd + 1
                );

                // Parse the JSON string into an object
                const parsedObject = JSON.parse(jsonString);
                return parsedObject;
              } catch (error) {
                console.error("Failed to parse object from message:", error);
                return null; // Return null or handle the error as needed
              }
            }

            const bidObject = extractObjectFromMessage(response.data);
            console.log(bidObject, "BID OBJECT");

            axios
              .post("/bidding/acceptBid", {
                bidId: bidObject.bidId,
                jobId: bidObject.requestedJobId,
                activityDesc: `Accepted bid for $${bidObject.bidAmount.toFixed(
                  2
                )}`,
                userName: `${user.first_name} ${user.last_name}`,
                host: window.location.host,
              })
              .then((res) => {
                if (res.data.ok) {
                  axios
                    .post("/bidding/closeBids", {
                      bidId: bidObject.bidId,
                      requestedJobId: bidObject.requestedJobId,
                      AssignedVendorId:
                        res.data.jobDetail[0].assigned_vendor_id,
                    })
                    .then(async (result) => {
                      console.log("BID ACCEPTED PROCESS COMPLETE");
                      await axios
                        .post("/fixbotChat/getVendorName", {
                          vendorId: res.data.jobDetail[0].assigned_vendor_id,
                        })
                        .then((result) => {
                          console.log(result, "VENDOR NAME");
                          setMessages([
                            ...messages,
                            {
                              user: user.first_name,
                              message: userMessage,
                            },
                            {
                              user: "Fixxie",
                              message:
                                response.data !== ""
                                  ? `The bid was accepted successfully. ${result.data.vendorName} will reach out to you soon!`
                                  : "Sorry we could not complete your request at this time.\nPlease try again later.",
                            },
                          ]);
                        });
                    });
                }
              });

            break;

          case response.data.includes("rejectBid"):
            const rejectBidObject = extractObjectFromMessage(response.data);
            console.log(rejectBidObject, "BID OBJECT");

            axios
              .post("/bidding/rejectBid", {
                bidId: rejectBidObject.bidId,
                jobId: rejectBidObject.requestedJobId,
              })
              .then((res) => {
                setMessages([
                  ...messages,
                  {
                    user: user.first_name,
                    message: userMessage,
                  },
                  {
                    user: "Fixxie",
                    message:
                      response.data !== ""
                        ? `The bid was rejected successfully.`
                        : "Sorry we could not complete your request at this time.\nPlease try again later.",
                  },
                ]);
              });

            break;

          case response.data.includes("addEvent"):
            const eventObject = extractObjectFromMessage(response.data);
            console.log(eventObject, "EVENT OBJECT");
            axios.post("/events/addEvents", eventObject).then((res) => {
              console.log("event submitted successfully");
              setMessages([
                ...messages,
                {
                  user: user.first_name,
                  message: userMessage,
                  attachments: attachments.map((file) =>
                    URL.createObjectURL(file)
                  ),
                },
                {
                  user: "Fixxie",
                  message:
                    response.data !== ""
                      ? `Event <b>${eventObject.Title}</b> has been successfully created!`
                      : "Sorry we could not complete your request at this time.\nPlease try again later.",
                },
              ]);
            });

            break;

          case response.data.includes("viewEvents"):
            function parseJobId(message) {
              // Define a regular expression to match "JobID: X" where X is a number
              const regex = /JobID:\s*(\d+)/;

              // Execute the regex on the input message
              const match = message.match(regex);

              // If a match is found, return the Job ID as a number
              if (match && match[1]) {
                return parseInt(match[1], 10);
              }

              // If no match is found, return null or throw an error
              return null; // Or throw new Error("Invalid message format");
            }

            const eventJobId = parseJobId(response.data);
            console.log(eventJobId, "Event Job ID");

            const eventResponse = await axios.post("/events/getEventDetails", {
              JobId: eventJobId,
            });

            if (eventResponse.data.ok) {
              console.log(eventResponse.data);

              // Format the dates
              for (const jobEvent of eventResponse.data.eventDetails) {
              }
              setMessages([
                ...messages,
                {
                  user: user.first_name,
                  message: userMessage,
                  attachments: attachments.map((file) =>
                    URL.createObjectURL(file)
                  ),
                },
                {
                  user: "Fixxie",
                  message:
                    response.data !== ""
                      ? `Sure! Here are the details for <b>Job ${eventJobId}:</b><br /><br />${eventResponse.data.eventDetails
                          .map((jobEvent, index) => {
                            const formatter = new Intl.DateTimeFormat("en-US", {
                              weekday: undefined, // Omit weekday if not needed
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true, // 12-hour clock with AM/PM
                            });
                            // Assuming 'addressParts' and 'formattedStart', 'formattedEnd' are defined correctly
                            const addressParts =
                              jobEvent.event_address.split(",");
                            const formattedStart = formatter.format(
                              new Date(jobEvent.start_time)
                            );
                            const formattedEnd = formatter.format(
                              new Date(jobEvent.end_time)
                            );

                            return `<b>${jobEvent.title}</b><br />${
                              jobEvent.assigned_to
                            }<br />${addressParts[0].trim()}, ${addressParts[1]?.trim()}<br />${formattedStart} - ${formattedEnd}<br /><br />`;
                          })
                          .join("")}`
                      : "Sorry we could not complete your request at this time.\nPlease try again later.",
                },
              ]);

              // console.log(
              //   `Sure! Here's the details for Job ${eventJobId}:<br /><br />${eventResponse.data.eventDetails.map(
              //     (jobEvent, index) =>
              //       `${jobEvent.title}<br />${jobEvent.assigned_to}<br />${jobEvent.event_address}<br />${formattedStart}<br />${formattedEnd}`
              //   )}`
              // );
            }

            break;

          default:
            console.log(
              "No specific string detected. Performing default action."
            );
            // Default action if no specific string is found
            setMessages([
              ...messages,
              {
                user: user.first_name,
                message: userMessage,
                attachments: attachments.map((file) =>
                  URL.createObjectURL(file)
                ),
              },
              {
                user: "Fixxie",
                message:
                  response.data !== ""
                    ? response.data
                    : "Sorry we could not complete your request at this time.\nPlease try again later.",
              },
            ]);
        }

        setSending(false);
        setUserMessage("");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Request failed:", error);
      }
    } finally {
      setSending(false);
      setUserMessage("");
      setAbortController(null);
      setAttachments([]);
    }
  };

  const handleStopClick = () => {
    console.log(abortController);
    if (abortController) {
      abortController.abort(); // Abort the ongoing request
      setSending(false); // Reset UI states

      setUserMessage("");
      setAbortController(null); // Cleanup
    } else {
      setStopTyping(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendClick();
    }
  };

  const openTermsAndConditions = () => {
    setIsOpenTerms(true);
    setTermsIsRead(true);
  };
  const closeTermsAndConditions = () => {
    setIsOpenTerms(false);
  };

  const openPrivatePolicy = () => {
    setPolicyIsRead(true);
    setIsOpenPolicy(true);
  };
  const closePrivatePolicy = () => {
    setIsOpenPolicy(false);
  };

  useEffect(() => {
    if (!user.vendorMode && window.location.href.includes("bidding")) {
      navigate("/dashboard/clientjobrequest");
    }
  }, [navigate, user.vendorMode]);

  const handleVendorMode = () => {
    console.log(user);
    if (user.is_vendor === 1) {
      dispatch({ state: user, type: "swapMode" });

      navigate(
        !user.vendorMode
          ? user.empRole === "Admin" || user.empRole === "Manager"
            ? "/dashboard/vendorMode/bidding"
            : "/dashboard/employeejobrequest"
          : "/dashboard/clientjobrequest"
      );
      // if (window.location.href.includes("bidding")) {
      //   navigate("/dashboard/clientjobrequest");
      // } else if (window.location.href.includes("events")) {
      //   navigate("/dashboard/eventCalendar");
      // } else if (window.location.href.includes("clientjobrequest")) {
      //   navigate("/dashboard/vendorMode/bidding");
      // } else if (window.location.href.includes("eventcalendar")) {
      //   navigate("/dashboard/vendorMode/events");
      // } else if (window.location.href.includes("employeejobrequest")) {
      //   navigate("/dashboard/clientjobrequest");
      // }

      sessionStorage.clear();
    } else {
      setVendorDescisionModal(true);
    }
  };

  const onSubmit = async () => {
    if (values === "Yes") {
      setCompanyIntake(true);
    } else {
      setVendorDescisionModal(false);
    }
  };

  const onSubmitCompany = async (values) => {
    try {
      await axios
        .post("/vendorMode/addCompany", {
          email: companyEmail,
          CompanyLegalName: companyLegalName,
          CompanyDbaName: companyDbaName,
          BusinessNumber: businessNumber,
          HSTRegistrationNumber: taxId,
          Services: `${selectedServices}`,
          InstitutionCode: institutionCode,
          TransitCode: transitCode,
          AccountNumber: accountNumber,
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.ok) {
            //email & password correct
            setLocationNotFound(false);
            setClientSecret(res.data.clientSecret);
            setClientID(res.data.clientID);
            setSubmitted(true);
            setCompanyIntake(false);
            setVendorDescisionModal(false);
            message.success(
              "Your account is created. Please verify your email."
            );
            dispatch({ state: user, type: "swapMode" });
            // handleSwitchChange();
            // axios.post("/vendorMode/changeVendorMode", {
            //   vendorMode: user.vendorMode,
            //   user: user,
            // });
            navigate(
              !user.vendorMode
                ? user.empRole === "Admin" || "Manager"
                  ? "/dashboard/vendorMode/bidding"
                  : "/dashboard/employeejobrequest"
                : "/dashboard/clientjobrequest"
            );
            sessionStorage.clear();
          } else if (res.data.notFound) {
            setLocationNotFound(true);
          }
        });
    } catch (err) {
      console.error(err.message);
    }
  };
  const services = [
    "Access & Security",
    "Appliance Repair",
    "Brick Layer",
    "Building Supplies",
    "Cabling and Data",
    "Carpenter",
    "Electrical",
    "Environmental",
    "Fire Protection",
    "Flooring",
    "Gas Fitter",
    "General Repair",
    "Glass Wholesale",
    "Heating",
    "Home Inspector",
    "Hot Tub Service",
    "HVAC",
    "Landscaping",
    "Lock Smith",
    "Miscellaneaous Supply",
    "Oil Burner Mechanic",
    "Paint Supply",
    "Pest Control",
    "Plaster & Paint Service",
    "Plumbing Supply",
    "Propane Service",
    "Property Management",
    "Property Restoration",
    "Refrigeration & AC",
    "Roofing",
    "Sheet Metal Worker",
    "Snow Clearing",
    "Tile Setter",
    "Ventilation",
    "Waste Management",
    "Windows & Doors",
    "Other",
  ];

  const toggleModal = () => {
    if (!isModalExpanded) {
      // Start expanding the modal
      setIsModalVisible(true);
      setIsModalExpanded(true);
    } else {
      // Start closing the modal
      setIsModalExpanded(false);
    }
  };

  // This function ensures the modal visibility is toggled after the transition is done
  const handleTransitionEnd = () => {
    if (!isModalExpanded) {
      setIsModalVisible(false);
    }
  };

  return (
    <>
      {!matchesXs && (
        <div
          style={{
            width: "100%",
          }}
        ></div>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {!matchesXs && (
        <Stack
          width={"100%"}
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Link
            // component="button" // Makes it behave like a button
            variant="body2"
            onClick={() => setReferrralmodal(true)}
            sx={{
              cursor: "pointer",
              // backgroundColor: "red",
              color: "#FFFFFF",
              textDecoration: "none",
              marginRight: "2rem",
            }}
          >
            <Typography color="#FFFFFF" variant="h6" fontWeight="400">
              Refer Friend
            </Typography>
          </Link>
        </Stack>
      )}
      {!matchesXs && (
        <Stack direction="row">
          <InputLabel
            style={{ cursor: "pointer", marginTop: "0.5rem", color: "#fff" }}
            htmlFor="vendorMode"
          >
            {user.vendorMode
              ? matchesXs
                ? "Client"
                : "Client Mode"
              : matchesXs
              ? "Vendor"
              : "Vendor Mode"}
          </InputLabel>
          <Switch
            id="vendorMode"
            color="hover"
            checked={user.vendorMode}
            onChange={() => {
              handleVendorMode();
            }}
          />
        </Stack>
      )}

      {matchesXs && <MobileSection handleVendorMode={handleVendorMode} />}
      <Profile matchesXs={matchesXs} />

      <Stack
        padding={"2rem 0.5rem"}
        style={{
          position: "fixed",
          right: "0px",
          bottom: "300px",
          backgroundColor: "rgba(1, 82, 47, 0.7)",
          color: "white",
          fontSize: "1rem",
          border: "1px solid black",
          cursor: "pointer",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          borderRight: "none",

          textOrientation: "upright",
          writingMode: "vertical-lr",
        }}
        onClick={() => setFeedbackModal(!feedbackModal)}
      >
        Feedback
      </Stack>

      <Stack
        style={{
          position: "fixed",
          right: "50px",
          bottom: "0",
          fontSize: "1rem",
          cursor: isModalExpanded ? "default" : "pointer",
          width: "30rem",
          height: isModalExpanded ? "45rem" : "3rem",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          transition: "height 0.3s ease-in-out",
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        <Stack
          id="headerContainer"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#01522f",
            padding: "0 0.5rem 0 0.5rem",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
          }}
          onClick={isModalExpanded ? null : toggleModal}
        >
          <Stack
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "1rem 0",
              gap: "0.5rem",
            }}
          >
            <Stack
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                borderRadius: "25px",
                padding: "0.25rem",
                width: "fit-content",
                border: "1px solid #017a46",
              }}
            >
              <Avatar src={FixxieAvatar} sx={{ width: 28, height: 28 }} />
            </Stack>
            <Typography variant="h2" style={{ color: "#FFFFFF" }}>
              Fixxie
            </Typography>
          </Stack>

          <Stack></Stack>

          <Stack
            onClick={toggleModal}
            // onClick={() => console.log(window.JRList)}
            style={{
              cursor: "pointer",
              alignSelf: "flex-start",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "15%",
              // backgroundColor: "pink",
            }}
          >
            {isModalExpanded ? (
              <Stack
                sx={{
                  height: "fit-content",
                  width: "fit-content",
                  // backgroundColor: "lightcoral",
                  padding: "0.75rem",
                  borderRadius: "50px",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.3)" },
                }}
              >
                <DownOutlined style={{ color: "#FFFFFF", fontSize: "1rem" }} />
              </Stack>
            ) : (
              <Stack
                sx={{
                  height: "fit-content",
                  width: "fit-content",
                  // backgroundColor: "lightcoral",
                  padding: "0.75rem",
                  borderRadius: "50px",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.3)" },
                }}
              >
                <UpOutlined style={{ color: "#FFFFFF", fontSize: "1rem" }} />
              </Stack>
            )}
            {/* <CloseOutlined style={{ color: "#FFFFFF", fontSize: "0.75rem" }} /> */}
          </Stack>
        </Stack>
        {isModalVisible && (
          <>
            <ChatContainer
              stopAccepted={stopAccepted}
              setStopAccepted={setStopAccepted}
              messages={messages}
              sending={sending}
              user={user}
              setMessages={setMessages}
              stopTyping={stopTyping}
              setStopTyping={setStopTyping}
            />
            {attachments.length > 0 && (
              <Stack
                width={"30rem"}
                minHeight={"3.5rem"}
                style={{
                  height: "fit-content !important",
                  padding: "0.15rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.15rem",
                  flexDirection: "row",
                  overflowX: "scroll",
                  // overflowY: "hidden",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "0 -2px 15px rgba(0, 0, 0, 0.5)",
                  zIndex: 10000,
                }}
              >
                {attachments.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      height: "2.5em",
                      width: "fit-content",
                      marginRight: "5px",
                    }}
                    onClick={() => removeAttachment(index)}
                  >
                    <img
                      style={{ height: "100%" }}
                      key={index}
                      src={URL.createObjectURL(image)}
                      alt=""
                    />
                  </div>
                ))}
              </Stack>
            )}
            <Stack
              id="footerContainer"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                backgroundColor: "#FFFFFF",
                padding: "0.5rem",
                gap: "0.5rem",
                boxShadow: "0 -4px 15px rgba(0, 0, 0, 0.5)",
              }}
            >
              <Button
                disableElevation
                disabled={sending}
                variant="contained"
                onClick={handleImageClick}
                sx={{
                  maxWidth: "2rem",
                  maxHeight: "2rem",
                  minWidth: "2rem",
                  minHeight: "2rem",
                }}
              >
                <PictureOutlined />
              </Button>
              <OutlinedInput
                onKeyDown={handleKeyDown}
                id="sendMessage"
                value={sending ? "" : userMessage}
                name="sendMessage"
                onChange={(e) => {
                  setUserMessage(e.target.value);
                }}
                placeholder="Type a message..."
                sx={{
                  minHeight: "2rem", // Set initial minimum height
                  height: userMessage === "" ? "2rem" : "auto", // Let the height grow with the content
                  flexGrow: 1,
                  overflow: "auto", // Enable scrolling if content overflows
                  padding: "0 0.5rem",
                }}
                multiline
                maxRows={10}
              />
              {userMessage !== "" && (
                <Button
                  disableElevation
                  disabled={sending || userMessage === ""}
                  variant="contained"
                  onClick={() => handleSendClick(userMessage, user.id)}
                  sx={{
                    maxWidth: "2rem",
                    maxHeight: "2rem",
                    minWidth: "2rem",
                    minHeight: "2rem",
                  }}
                >
                  <SendOutlined />
                </Button>
              )}
              {/* {sending && ( */}
              <Button
                disableElevation
                disabled={!stopAccepted}
                variant="contained"
                onClick={handleStopClick}
                sx={{
                  maxWidth: "2rem",
                  maxHeight: "2rem",
                  minWidth: "2rem",
                  minHeight: "2rem",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "1px",
                    minWidth: "0.75rem",
                    minHeight: "0.75rem",
                    maxWidth: "0.75rem",
                    maxHeight: "0.75rem",
                    backgroundColor: !stopAccepted ? "lightGray" : "#FFFFFF",
                  }}
                />
              </Button>
            </Stack>
          </>
        )}
      </Stack>

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Feedback"
        open={feedbackModal}
        onCancel={() => {
          setFeedbackModal(false);
        }}
        footer={false}
      >
        <FeedbackForm setFeedbackModal={setFeedbackModal} />
      </Modal>

      <Modal
        destroyOnClose={true}
        width="700px"
        title="Vendor Decision"
        open={vendorDescisionModal}
        onCancel={() => {
          setVendorDescisionModal(false);
        }}
        footer={false}
      >
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography variant="h3">Providing Services</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p">
              Do you wish to provide services to clients?
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
              <RadioGroup
                defaultValue=""
                name="customized-radios"
                row={true}
                value={values}
                onChange={(e) => {
                  setValues(e.target.value);
                  setShowNextPage(true);
                }}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  disabled={values === "Yes"}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  disabled={values === "No"}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <AnimateButton>
              <Button
                disableElevation
                disabled={!showNextPage}
                fullWidth
                size="large"
                variant="contained"
                onClick={onSubmit}
              >
                Continue
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Company Details"
        open={companyIntake}
        onCancel={() => {
          setCompanyIntake(false);
        }}
        footer={false}
      >
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="companyLegalName-signup">
                  Company Legal Name*
                </InputLabel>
                <OutlinedInput
                  required
                  id="companyLegalName-signup"
                  type="companyLegalName"
                  value={companyLegalName}
                  name="companyLegalName"
                  onChange={(e) => {
                    setCompanyLegalName(e.target.value);
                  }}
                  placeholder="Legal Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="companyDbaName-signup">
                  Company DBA Name*
                </InputLabel>
                <OutlinedInput
                  required
                  id="companyDbaName-signup"
                  type="companyDbaName"
                  value={companyDbaName}
                  name="companyDbaName"
                  onChange={(e) => setCompanyDbaName(e.target.value)}
                  placeholder="DBA Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="businessNumber-signup">
                  Business Number
                </InputLabel>
                <OutlinedInput
                  required
                  id="businessNumber-signup"
                  type="businessNumber"
                  value={businessNumber}
                  name="businessNumber"
                  onChange={(e) => setBusinessNumber(e.target.value)}
                  placeholder="Business Number"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="taxID-signup">
                  HST Registration #
                </InputLabel>
                <OutlinedInput
                  required
                  id="taxID-signup"
                  type="taxID"
                  value={taxId}
                  name="taxID"
                  onChange={(e) => setTaxId(e.target.value)}
                  placeholder="Tax ID"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Stack>
                  <InputLabel>Provided Service</InputLabel>
                  <Select
                    required
                    id="service"
                    name="service"
                    value={selectedServices}
                    onChange={(e) => setSelectedServices(e.target.value)}
                    multiple
                  >
                    {services.map((service, i) => (
                      <MenuItem value={service} key={`${service}_service`}>
                        {service}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  required
                  name="companyEmail"
                  id="companyEmail"
                  type="email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  placeholder="Company Email"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Bank institution code</InputLabel>
                <OutlinedInput
                  required
                  name="institutionCode"
                  id="institutionCode"
                  type="text"
                  value={institutionCode}
                  onChange={(e) => setInstitutionCode(e.target.value)}
                  placeholder="Institution Code"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Branch transit code</InputLabel>
                <OutlinedInput
                  required
                  name="transitCode"
                  id="transitCode"
                  type="text"
                  value={transitCode}
                  onChange={(e) => setTransitCode(e.target.value)}
                  placeholder="Branch transit code"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel>Account number</InputLabel>
                <OutlinedInput
                  required
                  name="accountNumber"
                  id="accountNumber"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  placeholder="Account number"
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                <Checkbox
                  checked={activeCreateAccount}
                  onChange={(e) => {
                    if (termsIsRead && policyIsRead) {
                      setActiveCreateAccount(e.target.checked);
                      setTermsReadError(false);
                    } else {
                      setTermsReadError(true);
                    }
                  }}
                />
                &nbsp; I have read and agreed to &nbsp;
                <Link
                  color={"#188bff"}
                  variant="subtitle2"
                  onClick={() => openTermsAndConditions()}
                >
                  Terms of Service
                </Link>
                &nbsp; and &nbsp;
                <Link
                  color={"#188bff"}
                  variant="subtitle2"
                  onClick={() => openPrivatePolicy()}
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography color={"#ff0000"}>
                {termsReadError &&
                  "*You must read the terms and conditions & privacy policy"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={!activeCreateAccount}
                  fullWidth
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmitCompany(values);
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Add Company
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>

        {/* Open Terms and Conditions */}
      </Modal>
      <Modal
        title="Terms And Conditions"
        width="700px"
        open={isOpenTerms}
        onCancel={closeTermsAndConditions}
        footer={[
          <Button key="submit" type="primary" onClick={closeTermsAndConditions}>
            Okay
          </Button>,
        ]}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
      >
        {isOpenTerms && (
          <TermsAndConditions
            signIn={true}
            openPolicy={() => {
              closeTermsAndConditions();
              openPrivatePolicy();
            }}
          />
        )}
      </Modal>

      {/* Open Private Policy */}
      <Modal
        title="Privacy Policy"
        width="700px"
        open={isOpenPolicy}
        onCancel={closePrivatePolicy}
        footer={[
          <Button key="submit" type="primary" onClick={closePrivatePolicy}>
            Okay
          </Button>,
        ]}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(80vh - 200px)" }}
      >
        {isOpenPolicy && <PrivacyPolicy signIn={true} />}
      </Modal>
      <Modal
        destroyOnClose={true}
        width="400px"
        title="Refer a friend"
        open={referralModal}
        onCancel={() => setReferrralmodal(false)}
        footer={false}
        centered
      >
        {referralModal && (
          <ReferralModal
            onCancel={() => setReferrralmodal(false)}
            displayReferSuccess={displayReferSuccess}
          />
        )}
      </Modal>
      {referAlert && (
        <Alert
          style={{
            zIndex: 10000,
            position: "absolute",
            top: "2rem",
            left: "calc(50vw - 8rem)",
            width: "16rem",
          }}
          severity="success"
        >
          Link has been sent. Thank you for referring.
        </Alert>
      )}
    </>
  );
};

export default HeaderContent;
