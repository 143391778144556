import React, { useCallback, useEffect, useRef, useState } from "react";
import Fullcalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ICalendarLink from "react-icalendar-link";
import "./calendar.css";
import { Modal } from "antd";
import { VendorTile } from "../../commonComponents/JRComponents/VendorPhases/VendorTile";
import { ClientTile } from "../../commonComponents/JRComponents/ClientPhases/ClientTile";

import axios from "axios";
import moment from "moment-timezone";
import ViewAvailability from "../../commonComponents/JRComponents/Availability/ViewAvailability";
import { localDate } from "../../commonComponents/util";
import { Button, Stack } from "@mui/material";
import AnimateButton from "../../../Layout/components/@extended/AnimateButton";
import { useUserState } from "../../../Context/UserContext";
moment.tz.setDefault("Canada/Newfoundland");

function createTile(user, jr, chargePercentages) {
  const fetchData = () => {
    console.log("NOTDEFINED");
  };
  if (user.vendorMode) {
    return (
      <VendorTile
        chargePercentages={chargePercentages}
        fetchJrData={fetchData}
        jrData={jr}
        key={jr.job_id}
      />
    );
  } else {
    return (
      <ClientTile
        chargePercentages={chargePercentages}
        fetchJrData={fetchData}
        jrData={jr}
        key={jr.job_id}
      />
    );
  }
}

function renderEventContent(eventInfo) {
  return (
    <div className="event">
      <p style={{ margin: "0", fontSize: "0.65rem" }}>
        <i>JRID {eventInfo.event._def.extendedProps.job_id}</i>
      </p>
      <p style={{ margin: "0", fontSize: "0.65rem" }}>
        {eventInfo.event._def.title}
      </p>
      <p style={{ margin: "0", fontSize: "0.65rem" }}>
        <b>{eventInfo.timeText}</b>
      </p>
    </div>
  );
}

const Calendar = (props) => {
  const [show, setShow] = useState(false);
  const [availability, setAvailability] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [selectedJR, setSelectedJR] = useState();
  const [currentEvent, setCurrentEvent] = useState();
  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0,
  });

  const fetchChargeData = useCallback(() => {
    axios.get("/finance/getChargePercentages").then((res) => {
      setChargePercentages(res.data);
    });
  }, []);

  const { user } = useUserState();

  const calendarComponentRef = useRef(null);
  const closeAvailability = () => {
    setAvailability(false);
  };

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const fetchCalendarEvents = useCallback(() => {
    axios.post("/calendar/getCalendarEvents", { user: user }).then((res) => {
      const events = res.data.events;
      setEventsList(events);
    });
  }, [user]);

  useEffect(() => {
    fetchChargeData();
    fetchCalendarEvents();
  }, [fetchCalendarEvents, fetchChargeData]);

  const createICSFile = (eventsList) => {
    // Format the date for the ICS file
    const formatDate = (date) => {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${year}${month}${day}T${hours}${minutes}${seconds}`;
    };

    // Create the file content
    let content =
      "BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//My Company//NONSGML Event Calendar//EN\n";
    for (const event of eventsList) {
      if (event.completed === 0) {
        const start = formatDate(new Date(event.start));
        const end = formatDate(new Date(event.end));
        content += `BEGIN:VEVENT\nUID:${event.event_id}\nSUMMARY:${event.title}\nDTSTAMP:${start}Z\nDTSTART:${start}Z\nTEND:${end}Z\nDESCRIPTION:${event.title}\nEND:VEVENT\n`;
      }
    }
    content += "END:VCALENDAR";

    // Download the file
    const filename = "events.ics";
    const blob = new Blob([content], { type: "text/calendar;charset=utf-8" });
    if (navigator.msSaveBlob) {
      // For IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleEventClick = (currentEvent) => {
    showModal();
    let JR = props.JRs.find(
      (x) => x.job_id === currentEvent.event._def.extendedProps.job_id
    );
    setSelectedJR(JR);
    setCurrentEvent(currentEvent.event._def);
  };

  console.log(eventsList, "EVENTS LIST");
  let icalEvent = [];
  let formattedEvents = [];
  if (eventsList.length > 0) {
    for (let i = 0; i < eventsList.length; i++) {
      if (eventsList[i].is_terminated === 0) {
        const startString = localDate(
          new Date(eventsList[i].start_date).setTime(
            new Date(eventsList[i].start_time).getTime()
          )
        );
        const endString = localDate(
          new Date(eventsList[i].start_date).setTime(
            new Date(eventsList[i].end_time).getTime()
          )
        );
        let endTime = new Date(eventsList[i].end_time).getTime();

        console.log(startString, "START STRING");
        console.log(endString, "end STRING");

        const eventData = {
          id: eventsList[i].event_id,
          title: eventsList[i].title,
          startTime: localDate(eventsList[i].start_time).getTime(),
          endTime: localDate(eventsList[i].end_time).getTime(),
          location: eventsList[i].event_address,
        };
        icalEvent.push(eventData);

        const calendarEvent = {
          id: eventsList[i].event_id,
          calId: i,
          job_id: eventsList[i].event_job_id,
          title: eventsList[i].title,
          start: new Date(startString).toISOString().slice(0, 19),
          end: new Date(endString).toISOString().slice(0, 19),
          completed: eventsList[i].completed,
          className:
            eventsList[i].completed === 1
              ? "completedEvent"
              : eventsList[i].employee_accepted === 1 &&
                endTime > new Date() &&
                new Date() > startString
              ? "onGoingEvent"
              : eventsList[i].employee_accepted === 1
              ? "acceptedEvent"
              : "proposedEvent",
        };
        formattedEvents.push(calendarEvent);
      }
    }
  } else {
    const calendarEvent = {
      title: "",
      description: "",
      start: "",
      end: "",
      location: "",
    };
    icalEvent = calendarEvent;
  }

  console.log(formattedEvents, "FORMATTED EVENTS");

  return (
    <div className="calendarContainer">
      <div className="calendar">
        <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
          <Button
            className="downloadButton"
            type="button"
            variant="contained"
            onClick={() => {
              createICSFile(formattedEvents);
            }}
            style={{
              marginBottom: "0.25rem",
              fontSize: "0.75rem",
              alignSelf: "center",
            }}
          >
            Download events.ics
          </Button>
        </Stack>
        <Fullcalendar
          ref={calendarComponentRef}
          aspectRatio={1.9}
          height="58vh"
          plugins={[daygridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={"timeGridWeek"}
          headerToolbar={{
            start: "timeGridDay,timeGridWeek",
            center: "title",
            end: "prev,today,next",
          }}
          events={formattedEvents}
          eventClick={(currentEvent) => {
            handleEventClick(currentEvent);
          }}
          // eventContent={renderEventContent}
        />
        <Modal
          destroyOnClose={true}
          width="800px"
          title="Event Overview"
          open={show}
          onCancel={hideModal}
          footer={false}
        >
          {show && (
            <div className="calendarModal">
              <div className="tileContainer">
                <p style={{ fontSize: "1.2rem", margin: "0" }}>
                  <b>
                    <em>{currentEvent.title}</em>
                  </b>
                </p>
                <p style={{ margin: "0" }}>
                  Download Event to add to Personal Calendar
                  <ICalendarLink
                    filename={`${currentEvent.title}`}
                    event={icalEvent.find(
                      (event) => event.id === parseInt(currentEvent.publicId)
                    )}
                  >
                    {" "}
                    Click Here
                  </ICalendarLink>
                </p>
                {createTile(props.user, selectedJR, chargePercentages)}
              </div>
              <Stack spacing={1}>
                <AnimateButton>
                  <Button
                    size="sm"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      hideModal();
                    }}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
              </Stack>
            </div>
          )}
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Reschedule Event"
          open={availability}
          onCancel={closeAvailability}
          footer={false}
        >
          <ViewAvailability onClose={closeAvailability} />
        </Modal>
      </div>
    </div>
  );
};

export default Calendar;
