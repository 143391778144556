import * as React from "react";

import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainFeaturedPost from "./MainFeaturedPost";
import "./landingPage.css";

const mainFeaturedPost = {
  title: "FixDen",
  descriptionOptions: [
    "Find the right tradesperson to fix your problem.",
    "Find the right tradesperson to fix your leaky sink.",
    "Find the right tradesperson to fix your broken toilet.",
    "Find the right tradesperson to fix your drywall.",
    "Find the right tradesperson to fix your flickering lights.",
    "Find the right tradesperson to fix your busted fence.",
  ],
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      light: "#77c7a2",
      dark: "#017a46",
      contrastText: "#fff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default function MainPage() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          padding: "0 !important",
          minWidth: "100% !important",
          height: "fit-content",
          display: "flex",
          justifyContent: "center", // Center horizontally
        }}
      >
        <MainFeaturedPost post={mainFeaturedPost} />
      </Container>
    </ThemeProvider>
  );
}
