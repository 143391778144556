import styles from "./JobDisplay.module.css";
import { VendorTile } from "../../commonComponents/JRComponents/VendorPhases/VendorTile";
import { useCallback, useEffect, useState } from "react";
import { useUserState } from "../../../Context/UserContext";
import { ClientTile } from "../../commonComponents/JRComponents/ClientPhases/ClientTile";
import { JRSkeleton } from "../../commonComponents/util";
import { useJRContext } from "../../../Context/JobRequestContext";
import { useMediaQuery } from "@mui/material";

const date_today = new Date();
date_today.setHours(0);

const JobDisplay = (props) => {
  const { user } = useUserState();
  const { openJR } = useJRContext();

  const createTile = useCallback(
    (jr, overdue) => {
      if (user.vendorMode && jr.send_market === 1) {
        return (
          <VendorTile
            chargePercentages={props.chargePercentages}
            fetchJrData={props.fetchJrData}
            setShowSubmitForm={props.setShowSubmitForm}
            is_market={props.isMarket}
            overdue={overdue}
            setMapFocus={props.setMapFocus}
            jrData={jr}
            key={jr.job_id}
          />
        );
      } else {
        return (
          <ClientTile
            chargePercentages={props.chargePercentages}
            fetchJrData={props.fetchJrData}
            setMapFocus={props.setMapFocus}
            jrData={jr}
            key={jr.job_id}
          />
        );
      }
    },
    [
      props.chargePercentages,
      props.fetchJrData,
      props.isMarket,
      props.setMapFocus,
      props.setShowSubmitForm,
      user.vendorMode,
    ]
  );
  const createTiles = useCallback(() => {
    let JRs = props.currentJRs;
    const jrList = [];
    for (let jr of JRs) {
      if (typeof jr.job_id === "undefined") {
        jrList.push(JRSkeleton(jr.id));
        continue;
      }

      jrList.push(createTile(jr, !props.isMarket ? date_today : false));
    }
    if (jrList.length === 0) {
      return (
        <h1 className={styles.emptyDisplay}>
          There is no any jobs listed in the market.
        </h1>
      );
    }
    return jrList;
  }, [createTile, props.currentJRs, props.isMarket]);

  const [JRTiles, setJRTiles] = useState(createTiles());

  useEffect(() => {
    setJRTiles(createTiles());
  }, [createTiles]);

  const mobileView = useMediaQuery("(max-width: 600px)");

  return (
    <div className={styles.jobDisplay} style={{ overflowY: "scroll" }}>
      {/* <button
        onClick={() => {
          console.log(typeof props.setMapFocus);
        }}
      >
        TEST
      </button> */}
      <div
        className={styles.jobDisplayList}
        // style={{ height: "100%" }}
        style={{ height: openJR ? "75%" : "100%" }}
      >
        {openJR === null ? (
          <>{JRTiles}</>
        ) : (
          <>
            {/* create selected tile */}
            {createTile(
              openJR,
              props.isMarket ? false : openJR.job_due <= date_today
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default JobDisplay;
