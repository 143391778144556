import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Modal } from "antd";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import AddEvent from "../Events/AddEvent";
import AddTroubleshooting from "../TroubleShoot/AddTroubleshooting";
import AddAction from "../Actions/AddAction";
import VendorReview from "../Review/VendorReview";
import StarRating from "../Review/StarRating";

import moment from "moment-timezone";
import Invoice from "../Invoice/Invoice";
moment.tz.setDefault("Canada/Newfoundland");

function ExpandedTilePhase2(props) {
  const { user } = useUserState();
  const [reworkDetailsModal, setReworkDetailsModal] = useState();
  const [workDoneModal, setWorkDoneModal] = useState(false);
  const [workNotDoneModal, setWorkNotDoneModal] = useState(false);
  const [uncompletedEvents, setUncompletedEvents] = useState([]);
  const [sentToReview, setSentToReview] = useState(false);

  const [invoiceModal, setInvoiceModal] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [reviewingStatus, setReviewingStatus] = useState(false);
  const [reviewSent, setReviewSent] = useState(false);

  const workIsUnderReview = () => {
    const formObject = {
      JobId: props.jrData.job_id,
      activityDesc: `Workdone is under review.`,
      userName: `${user.first_name} ${user.last_name}`,
    };

    axios.post("/jobRequest/updateToReviewing", formObject).then((res) => {
      props.jrDetails();
      setWorkDoneModal(false);
      setWorkNotDoneModal(false);
      setReviewingStatus(true);
      console.log(props.jrData.job_status);
      if (res.data.ok) {
        props.fetchData();
        props.fetchJrData();
      }
    });
  };

  const handleInvoiceSubmit = () => {
    let acceptedScope = [];
    for (let item of props.scopeDetails) {
      if (item.rejected === 0 && item.is_accepted === 1) {
        acceptedScope.push(item);
      }
    }

    const body = {
      jobId: props.jrData.job_id,
    };

    axios.post("/invoice/newInvoiceLine", body).then(() => {});
  };

  const fetchUncompletedEvents = () => {
    axios
      .post("/events/getUncompletedEvents", { JobId: props.jrData.job_id })
      .then(({ data }) => {
        const uncompletedEvents = data.uncompletedEvents;
        setUncompletedEvents(uncompletedEvents);
        uncompletedEvents.length > 0
          ? setWorkNotDoneModal(true)
          : setWorkDoneModal(true);
      });
  };

  const completeAllEvents = () => {
    axios
      .post("/events/completeUncompletedEvents", { JobId: props.jrData.job_id })
      .then(() => {
        workIsUnderReview();
      });
  };
  console.log(props.actionList);
  return (
    <>
      <div className="phase" id="phase-2">
        <div className="box">
          <div>
            <AddEvent
              events={props.events}
              troubleshootingImages={props.troubleshootingImages}
              troubleshootingList={props.troubleshootingList}
              actionImages={props.actionImages}
              actionList={props.actionList}
              fetchData={props.fetchData}
              jr={props.jrData}
            />
          </div>
        </div>

        <div className="box">
          <div>
            <AddTroubleshooting
              fetchData={props.fetchData}
              troubleshootDisplay={true}
              troubleshootingImages={props.troubleshootingImages}
              troubleshootingList={props.troubleshootingList}
              jr={props.jrData}
              events={props.events}
            />
          </div>
        </div>

        <div className="box">
          <div>
            <AddAction
              vendorActions={true}
              fetchData={props.fetchData}
              actionDisplay={true}
              actionImages={props.actionImages}
              actionList={props.actionList}
              jr={props.jrData}
              events={props.events}
            />
          </div>
        </div>

        <div className="box">
          <div>
            <Typography style={{ fontWeight: "600" }}>Activity Log</Typography>
            {props.activityList?.length > 0 && (
              <table className="table">
                <tbody>
                  {props.activityList?.map((item, i) => (
                    <tr
                      className="tableRowHover"
                      style={{ height: "1.7rem" }}
                      key={item.activity_log_id}
                    >
                      <td style={{ textAlign: "center" }} className="td">
                        {item.user_name}
                      </td>
                      <td style={{ textAlign: "center" }} className="td">
                        {item.activity_logs}
                      </td>
                      <td style={{ textAlign: "center" }} className="td">
                        {moment(new Date(item.activity_time)).format(
                          "MMM Do YYYY, h:mm a"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {typeof props.reworkDetails !== "undefined" &&
          props.reworkDetails.length > 0 &&
          props.jrData.job_status === "Scheduling" && (
            <div
              style={{
                marginTop: "1rem",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="phase"
            >
              <Button
                style={{ width: "50%" }}
                color="primary"
                variant="contained"
                onClick={() => setReworkDetailsModal(true)}
                sx={{ ml: 2, float: "right" }}
              >
                View Rework Request
              </Button>
            </div>
          )}
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Invoice"
          open={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          footer={false}
        >
          <Invoice
            clientInvoiceDetails={props.clientInvoiceDetails[0]}
            vendorInvoiceDetails={props.vendorInvoiceDetails[0]}
            invoice={props.invoice}
            invoiceDetails={props.invoiceDetails}
            jrData={props.jrData}
          />
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Rework Request Details"
          open={reworkDetailsModal}
          onCancel={() => setReworkDetailsModal(false)}
          footer={false}
        >
          {reworkDetailsModal && (
            <Grid container spacing={1}>
              {props.reworkDetails.length < 2 && (
                <Grid item xs={12}>
                  <Stack spacing={1} direction={"column"}>
                    <h4>
                      {props.jrData.clientName} has requested rework of the Job
                      Request on{" "}
                      {moment(
                        new Date(props.reworkDetails[0].rework_request_date)
                      ).format("MMM Do YYYY, h:mm a")}
                    </h4>
                    <h4>Rework Description:</h4>
                    <p>{props.reworkDetails[0].rework_description}</p>
                  </Stack>
                </Grid>
              )}
              {props.reworkDetails.length > 1 &&
                props.reworkDetails?.map((item, i) => (
                  <Grid key={item.rework_id} item xs={12}>
                    <Stack spacing={1} direction={"column"}>
                      <Typography
                        style={{
                          margin: "0",
                          borderBottom: "1px solid black",
                          fontWeight: "700",
                        }}
                      >
                        Rework #{i + 1}
                      </Typography>
                      <h4>
                        {props.jrData.clientName} has requested rework of the
                        Job Request on{" "}
                        {moment(
                          new Date(props.reworkDetails[i].rework_request_date)
                        ).format("MMM Do YYYY, h:mm a")}
                      </h4>
                      <h4>Rework Description:</h4>
                      <p>{props.reworkDetails[i].rework_description}</p>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
          )}
        </Modal>
        {props.jrData.job_status === "Scheduling" &&
        !sentToReview &&
        props.events.length >= 1 ? (
          <div>
            <Button
              onClick={() => {
                fetchUncompletedEvents();
                console.log(props.events);
              }}
              id="workIsDone"
              name="workIsDone"
              size="small"
              type="button"
              variant="contained"
              color="primary"
              style={{
                marginLeft: "5px",
                marginRight: "15px",
                marginTop: "15px",
                float: "right",
              }}
            >
              Send Invoice
            </Button>
          </div>
        ) : props.jrData.job_status === "Reviewing" || sentToReview === true ? (
          <>
            <Typography>
              You confirmed that the work has been done and send to Client for
              reviewing with invoice.
            </Typography>
            <Typography>
              Waiting for client approval before proceeding.
            </Typography>
            <Button
              onClick={() => {
                setInvoiceModal(true);
              }}
              size="sm"
              color="primary"
              variant="contained"
              style={{ marginTop: "25px", float: "right" }}
            >
              View Invoice
            </Button>
          </>
        ) : (
          props.jrData.job_status !== "Scheduling" && (
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                style={{
                  marginTop: "25px",
                  float: "right",
                  marginLeft: "15px",
                }}
              >
                Work is done and payment is processed.
              </Typography>
              <Button
                onClick={() => {
                  setInvoiceModal(true);
                }}
                size="small"
                color="primary"
                variant="contained"
                // style={{ marginLeft: "50px" }}
              >
                View Invoice
              </Button>
              {console.log(props.vendorReview, "vendorREVIEWWWWWWWWWWWWWWWW")}
              {props.vendorReview === false && (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    setShowReview(true);
                    setReviewSent(true);
                  }}
                  variant="contained"
                  type="button"
                  // style={{ marginLeft: "30px" }}
                >
                  Leave Review
                </Button>
              )}
            </Stack>
          )
        )}
      </div>
      {showReview && (
        <VendorReview
          fetchData={props.fetchData}
          fetchJrData={props.fetchJrData}
          jrData={props.jrData}
          vendorReview={props.vendorReview}
          showReview={showReview}
          setShowReview={setShowReview}
          userName={`${user.first_name} ${user.last_name}`}
        />
      )}
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Confirm Work"
        open={workDoneModal}
        onCancel={() => {
          setWorkDoneModal(false);
        }}
        footer={false}
      >
        {workDoneModal && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <Typography>
                  Are you sure that all work has been completed and send to
                  client for reviewing?
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <AnimateButton>
                  <Button
                    style={{
                      backgroundColor: "green",
                    }}
                    variant="contained"
                    onClick={() => {
                      handleInvoiceSubmit();
                      workIsUnderReview();
                      setWorkDoneModal(false);
                      setSentToReview(true);
                      console.log(props.jrData);
                    }}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Confirm
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => setWorkDoneModal(false)}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        width="700px"
        title="Uncompleted Events"
        open={workNotDoneModal}
        onCancel={() => setWorkNotDoneModal(false)}
        footer={false}
      >
        {workNotDoneModal && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <Typography>
                  All events for this job request hasn't been completed. Please
                  check off the events to complete them before clicking the{" "}
                  <em>"Send to Review"</em> button.
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <AnimateButton>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setWorkNotDoneModal(false)}
                    sx={{ ml: 2, float: "right" }}
                  >
                    Okay
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Modal>
    </>
  );
}

export default ExpandedTilePhase2;
