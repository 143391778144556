import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useUserState } from "../../../../Context/UserContext";
import AnimateButton from "../../../../Layout/components/@extended/AnimateButton";
import { FilePdfTwoTone, MessageOutlined } from "@ant-design/icons";
import StarRating from "../Review/StarRating";
import BidDetails from "../Bids/BidDetails";
import ScopeDetails from "../Scope/ScopeDetails";
import ScopeTable from "../Scope/ScopeTable";
import PropTypes from "prop-types";
import BidListingTable from "../Bids/BidListingTable";
import NewPaymentMethod from "../../../NewPaymentMethod";

function ExpandedTilePhase2(props) {
  let navigate = useNavigate();
  const [bidDetailsModal, setBidDetailsModal] = useState(false);
  const [currentBid, setCurrentBid] = useState(-1);
  const [bidModal, setBidModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [showBids, setShowBids] = useState(false);
  const { user } = useUserState();
  const [openScopeModal, setOpenScopeModal] = useState(false);
  const [vendorDetails, setVendorDetails] = useState([]);

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [paymentRequiredModal, setPaymentRequiredModal] = useState(false);
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);

  const [currentScope, setCurrentScope] = useState(props.scopeDetails[0]);
  const [chargePercentages, setChargePercentages] = useState({
    clientPercentage: 0,
    vendorPercentage: 0,
  });

  useEffect(() => {
    setCurrentScope(props.scopeDetails[0]);
  }, [
    chargePercentages.clientPercentage,
    chargePercentages.vendorPercentage,

    props.scopeDetails,
    user.vendorMode,
  ]);

  useEffect(() => {
    axios.get("/finance/getChargePercentages").then((response) => {
      setChargePercentages(response.data);
    });
  }, []);

  const showBidsHandler = () => {
    setShowBids(true);
    axios.post("/notifications/bidViewed", { jobID: props.jrData.job_id });
  };

  const onClose = () => {
    setBidDetailsModal(false);
  };

  const closeRejectModal = () => {
    setCloseModal(false);
  };

  const onCloseBidModal = () => {
    setBidModal(false);
  };

  const fetchPaymentMethod = () => {
    axios.get("/stripe/getPreferredPaymentMethod").then((res) => {
      if (res.data.paymentMethod !== null && res.data.ok) {
        setPaymentMethod(true);
      } else {
        setPaymentMethod(false);
      }
    });
  };

  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const fetchVendorData = useCallback(() => {
    // console.log(props.jrData);
    // assigned_vendor_id should be null until bid is accepted
    if (props.jrData.assigned_vendor_id !== null) {
      axios
        .post("/jobRequest/getVendorDetails", {
          vendorID: props.jrData.assigned_vendor_id,
        })
        .then(({ data }) => {
          // console.log(props.jrData.assigned_vendor_id);
          setVendorDetails(data.vendorDetails);
        });
    }
  }, [props.jrData.assigned_vendor_id]);

  useEffect(() => {
    fetchVendorData();
  }, [fetchVendorData, props.jrData.job_id]);

  const newChat = (vendorId, vendorName, bidId) => {
    axios
      .post("/communication/createConversation", {
        UserId: vendorId,
        JobId: props.jrData.job_id,
        VendorName: vendorName,
        BidChat: 1,
        BidId: bidId,
      })
      .then((res) => {
        navigate(`/dashboard/chat/${res.data.chatId}`);
      });
  };

  let bidList = [];

  const rejectBid = (bidList, i) => {
    let bid = bidList[i];
    let bidObject = {
      bidId: bid.bid_id,
      bidVendorId: bid.vendor_id,
      requestedJobId: bid.requested_job_id,
      workToBeDone: bid.work_to_be_done,
      labourCharge: bid.labour_charge,
      estimatedHours: bid.estimated_hours,
      estimatedMaterials: bid.estimated_materials,
      bidAmount: bid.bid_amount,
      bidDate: bid.bid_date,
      notes: bid.notes,
    };

    axios
      .post("/bidding/rejectBid", {
        bidId: bidObject.bidId,
        requestedJobId: bidObject.requestedJobId,
      })
      .then((res) => {
        onClose();
        setCloseModal(false);
        props.fetchData();
      });
  };

  const acceptBid = (bidList, i) => {
    let bid = bidList[i];
    let bidObject = {
      bidId: bid.bid_id,
      bidVendorId: bid.bid_vendor_id,
      requestedJobId: bid.requested_job_id,
      workToBeDone: bid.work_to_be_done,
      labourCharge: bid.labour_charge,
      estimatedHours: bid.estimated_hours,
      estimatedMaterials: bid.estimated_materials,
      bidAmount: bid.bid_amount,
      bidDate: bid.bid_date,
      notes: bid.notes,
      host: window.location.host,
    };

    axios
      .post("/bidding/acceptBid", {
        bidId: bidObject.bidId,
        jobId: bidObject.requestedJobId,
        activityDesc: `Accepted bid for $${bidObject.bidAmount.toFixed(2)}`,
        userName: `${user.first_name} ${user.last_name}`,
        host: window.location.host,
      })
      .then((res) => {
        console.log(res.data.jobDetail);
        if (res.data.ok) {
          axios
            .post("/bidding/closeBids", {
              bidId: bidObject.bidId,
              requestedJobId: bidObject.requestedJobId,
              AssignedVendorId: res.data.jobDetail[0].assigned_vendor_id,
            })
            .then((res) => {
              props.fetchData();
              props.fetchJrData();
              onClose();
              onCloseBidModal();
            });
        } else {
          console.error("Error: accept bid failed");
        }
      });
  };

  const onSuccess = () => {
    setAddPaymentMethodModal(false);
    setPaymentRequiredModal(false);
    fetchPaymentMethod();
  };

  console.log(currentScope);
  // console.log(props.jrData.assigned_vendor_id);
  const [mobileModal, setMobileModal] = useState(false);
  const mobileView = useMediaQuery("(max-width: 500px)");
  console.log(props.jrData);
  console.log(props.bidDetails);
  return (
    props.bidDetails.length > 0 && (
      <>
        {!mobileView ? (
          <>
            {props.jrData.assign_vendor_id === null ||
              (props.jrData.assign_vendor_id !== null && (
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {props.bidDetails[0].is_accepted === 0 && (
                    <>
                      <Stack>
                        <BidListingTable
                          bidDetails={props.bidDetails}
                          newChat={newChat}
                          setBidDetailsModal={(value) =>
                            setBidDetailsModal(value)
                          }
                          setCurrentBid={setCurrentBid}
                          mobileView={mobileView}
                        />
                      </Stack>
                    </>
                  )}
                </Stack>
              ))}
            {props.scopeDetails !== "" && props.scopeDetails.length > 0 && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack spacing={1} direction={"row"}>
                      <Typography className="title">
                        Service Agreement :{" "}
                        <FilePdfTwoTone
                          className="icon-size"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.setAgreementOpen(true);
                          }}
                        />
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {props.scopeDetails?.map((item, i) => (
                      <Stack spacing={1} direction={"row"}>
                        <Grid item sm={12} md={8}>
                          <Stack>
                            <Typography className="title">
                              Work to be done:
                            </Typography>

                            <Typography
                              style={{
                                width: "350px",
                                overflowWrap: "break-word",
                                wordWrap: "break-word",
                                marginBottom: 5,
                              }}
                            >
                              {item.work_to_be_done}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography className="title">Notes: </Typography>
                            <Typography
                              style={{
                                width: "350px",
                                overflowWrap: "break-word",
                                wordWrap: "break-word",
                                marginBottom: 5,
                              }}
                            >
                              {item.notes}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography className="title">Charges:</Typography>
                            <Grid item xs={12} marginTop="1em">
                              <ScopeTable
                                lines={currentScope?.scopeLines}
                                amount={currentScope?.scope_amount}
                                chargePercentages={chargePercentages}
                              />
                              <hr style={{ marginTop: "1em" }} />
                            </Grid>
                          </Stack>
                        </Grid>
                      </Stack>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        ) : (
          <>
            <>
              {props.scopeDetails !== "" && props.scopeDetails.length > 0 && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Stack spacing={1} direction={"row"}>
                        <Typography className="title">
                          Service Agreement :{" "}
                          <FilePdfTwoTone
                            className="icon-size"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setAgreementOpen(true);
                            }}
                          />
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      {props.scopeDetails?.map((item, i) => (
                        <Stack spacing={1} direction={"row"}>
                          <Grid item sm={12} md={8}>
                            <Stack>
                              <Typography className="title">
                                Work to be done:
                              </Typography>

                              <Typography
                                style={{
                                  width: "350px",
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  marginBottom: 5,
                                }}
                              >
                                {item.work_to_be_done}
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography className="title">Notes: </Typography>
                              <Typography
                                style={{
                                  width: "350px",
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  marginBottom: 5,
                                }}
                              >
                                {item.notes}
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography className="title">
                                Charges:
                              </Typography>
                              <Grid item xs={12} marginTop="1em">
                                <ScopeTable
                                  lines={currentScope?.scopeLines}
                                  amount={currentScope?.scope_amount}
                                  chargePercentages={chargePercentages}
                                />
                                <hr style={{ marginTop: "1em" }} />
                              </Grid>
                            </Stack>
                          </Grid>
                        </Stack>
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}
            </>

            <Stack direction={"row"} justifyContent={"space-between"}>
              {props.bidDetails[0]?.is_accepted === 0 && (
                <Stack>
                  <BidListingTable
                    bidDetails={props.bidDetails}
                    newChat={newChat}
                    setBidDetailsModal={(value) => setBidDetailsModal(value)}
                    setCurrentBid={setCurrentBid}
                    mobileView={mobileView}
                  />
                </Stack>
              )}
            </Stack>
          </>
        )}
        <Modal
          destroyOnClose={true}
          width="300px"
          title="Options"
          open={mobileModal}
          onCancel={() => {
            setMobileModal(false);
          }}
          footer={false}
        >
          <AnimateButton>
            <Stack direction={"row"} justifyContent={"space-between"}></Stack>
          </AnimateButton>
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Scope of Work"
          open={openScopeModal}
          onCancel={() => {
            setOpenScopeModal(false);
          }}
          footer={false}
        >
          <ScopeDetails
            fetchData={props.fetchData}
            setOpenScopeModal={setOpenScopeModal}
            vendorData={vendorDetails}
            jrData={props.jrData}
            scopeDetails={props.scopeDetails}
          />
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Bids"
          open={showBids}
          onCancel={() => setShowBids(false)}
          footer={false}
        >
          <>
            {console.log(props.bidDetails)}
            {props.bidDetails.length > 0 ? (
              <Grid
                container
                spacing={1}
                width={"100%"}
                justifyContent={"space-around"}
              >
                {props.bidDetails
                  .sort((a, b) => b.bid_id - a.bid_id)
                  .map((bid, i) => (
                    <Grid
                      container
                      onClick={() => {
                        setBidDetailsModal(true);
                        setCurrentBid(i);
                      }}
                      className="hover"
                      key={bid.bid_id}
                      justifyContent={"center"}
                      alignItems={"center"}
                      direction={mobileView ? "row" : "column"}
                      marginBottom={"1rem"}
                      // sx={{
                      //   "&:last-child td, &:last-child th": {
                      //     border: 0,
                      //   },
                      // }}
                    >
                      {(() => {
                        bidList.push(bid);
                      })()}
                      <Stack
                        width={mobileView ? "25rem" : "100%"}
                        direction={mobileView ? "column" : "row"}
                        spacing={1}
                        border={"1px solid #dddddd"}
                        borderRadius={"5px"}
                        boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        padding={"0.25rem"}
                      >
                        <Grid item xs={mobileView ? 12 : 6} width={"100%"}>
                          <Stack
                            direction={mobileView ? "column" : "row"}
                            spacing={1}
                          >
                            <Typography variant="p">
                              {bid.vendor_legal_name}
                            </Typography>
                            <Typography variant="p">
                              ${bid.bid_amount.toFixed(2)}
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={mobileView ? 12 : 2} width={"100%"}>
                          {bid.is_accepted === 0 && bid.is_closed === 0 && (
                            <Stack
                              direction={"row"}
                              spacing={1}
                              width="fit-content"
                            >
                              {props.setOpenBid && (
                                <IconButton
                                  onClick={() => {
                                    newChat(
                                      bid.bid_vendor_id,
                                      bid.vendor_legal_name,
                                      bid.bid_id
                                    );
                                    setBidDetailsModal(false);
                                  }}
                                  size="sm"
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                >
                                  <MessageOutlined />
                                </IconButton>
                              )}
                            </Stack>
                          )}
                          {bid.is_accepted === 1 && bid.is_closed === 0 && (
                            <b style={{ color: "green" }}>Bid Accepted</b>
                          )}
                          {bid.is_accepted === 0 && bid.is_closed === 1 && (
                            <b style={{ color: "red" }}>Bid Closed</b>
                          )}
                        </Grid>

                        <Grid item xs={mobileView ? 12 : 4} width={"100%"}>
                          {<StarRating rating={bid.rating} />}
                        </Grid>
                      </Stack>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              "There are no bids to display"
            )}
          </>
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Reject Bid"
          open={closeModal}
          onCancel={closeRejectModal}
          footer={false}
        >
          {closeModal && (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography>
                  Are you sure you want to reject the bid from{" "}
                  <b>{bidList[currentBid].vendor_legal_name}</b> for{" "}
                  <b>${parseInt(bidList[currentBid].bid_amount).toFixed(2)}</b>?
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack spacing={1}>
                  <AnimateButton>
                    <Button
                      size="sm"
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => rejectBid(bidList, currentBid)}
                      sx={{ ml: 2, float: "right" }}
                    >
                      Reject
                    </Button>
                    <Button
                      size="sm"
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={closeRejectModal}
                      sx={{ ml: 2, float: "right" }}
                    >
                      Cancel
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Bid Details"
          open={bidDetailsModal}
          onCancel={onClose}
          footer={false}
        >
          <BidDetails
            paymentMethod={paymentMethod}
            openAgreement={props.openAgreement}
            chargePercentages={props.chargePercentages}
            onClose={onClose}
            bidDetails={bidList[currentBid]}
            bidIndex={currentBid}
            setBidIndex={setCurrentBid}
            acceptBid={() => setBidModal(true)}
            rejectBid={() => setCloseModal(true)}
            paymentRequiredModal={paymentRequiredModal}
            setPaymentRequiredModal={setPaymentRequiredModal}
          />
          {/* {bidDetailsModal && (
            
            <BidDetails
              openAgreement={props.openAgreement}
              chargePercentages={props.chargePercentages}
              onClose={onClose}
              bidDetails={bidList[currentBid]}
              bidIndex={currentBid}
              setBidIndex={setCurrentBid}
              acceptBid={() => setBidModal(true)}
              rejectBid={() => setCloseModal(true)}
            />
          )} */}
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Add Payment"
          open={paymentRequiredModal}
          onCancel={() => setPaymentRequiredModal(false)}
          footer={false}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Please add atleast <b>ONE</b> payment method in order to accept
              the bid.
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                gap: "1rem",
                marginTop: "2rem",
              }}
            >
              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={() => setBidDetailsModal(false)}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                type="button"
                variant="contained"
                color="primary"
                onClick={() => setAddPaymentMethodModal(true)}
              >
                Add Card
              </Button>
            </Stack>
          </Stack>
        </Modal>
        <Modal
          destroyOnClose={true}
          width="700px"
          title="Accept Bid"
          open={bidModal}
          onCancel={onCloseBidModal}
          footer={false}
        >
          {bidModal && (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography>
                  I accept the bid from{" "}
                  <b>{bidList[currentBid].vendor_legal_name}</b> for{" "}
                  <b>${parseInt(bidList[currentBid].bid_amount).toFixed(2)}</b>.
                  I agree to pay the charges as per the{" "}
                  <b style={{ color: "#01522f" }}>
                    <a onClick={() => props.setAgreementOpen(true)}>
                      Service Agreement
                    </a>
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack spacing={1}>
                  <AnimateButton>
                    <Button
                      size="sm"
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        acceptBid(bidList, currentBid);
                      }}
                      sx={{ ml: 2, float: "right" }}
                    >
                      Accept
                    </Button>
                    <Button
                      size="sm"
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={onCloseBidModal}
                      sx={{ ml: 2, float: "right" }}
                    >
                      Cancel
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Modal>
        <Modal
          title="Add Payment Details"
          open={addPaymentMethodModal}
          onCancel={() => setAddPaymentMethodModal(false)}
          footer={false}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(90vh - 200px)" }}
        >
          <NewPaymentMethod
            onSuccess={onSuccess}
            successUrl={window.location.origin + "/dashboard/clientjobrequest"}
          />
        </Modal>
      </>
    )
  );
}

ExpandedTilePhase2.protoTypes = {
  jrData: PropTypes.arrayOf(PropTypes.object),
  setOpenBid: PropTypes.func,
};

ExpandedTilePhase2.defaultProps = {
  jrData: [],
  setOpenBid: () => {},
};

export default ExpandedTilePhase2;
